import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import * as Yup from "yup";
import { USERS_ROUTE } from '../../../routes/types'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'

const validationSchema = Yup.object().shape({
  'title': Yup.string().required("Campo requerido"),
  'apellidos': Yup.string().required("Campo requerido"),
  'Email': Yup.string().email('Invalid email').required("Campo requerido"),
  'tipo-de-usuario': Yup.string().required("Campo requerido"),
  'estatus': Yup.string().required("Campo requerido"),
  'Constraseña': Yup.string().required("Campo requerido"),
});

const EditUser = ({ history, userData}) => {
  userData = JSON.parse(localStorage.getItem('editarUsuario'))
  const data = userData["user"];
  const edit = userData["accion"];
  if (!data['item_id'])
    history.push(`/app${USERS_ROUTE}`)
  return (

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${USERS_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">{edit === true ? "Editar" : "Consultar" } usuario</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                'title': data['Nombre(s)'],
                'apellidos': data['Apellidos'],
                'tipo-de-usuario': data['Tipo de Usuario'],
                'estatus': data['Estatus'],
                'Email': data['Email'][0]["value"],
                'Constraseña': data['Contraseña-ref']
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, actions, history, data['item_id'])}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='title'
                          name='title'
                          placeholder="Escribe el nombre"
                          onChange={props.handleChange}
                          value={props.values['title']}
                          isInvalid={!!props.errors.title}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.title}
                        </Form.Control.Feedback>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Apellidos<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='apellidos'
                          name='apellidos'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['apellidos']}
                          isInvalid={!!props.errors.apellidos}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.apellidos}
                        </Form.Control.Feedback>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Tipo de usuario<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          as="select"
                          id='tipo-de-usuario'
                          name='tipo-de-usuario'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['tipo-de-usuario']}
                          isInvalid={!!props.errors['tipo-de-usuario']}
                        >

                          <option>Selecciona un tipo de usuario</option>
                          <option value="Owner">Owner</option>
                          <option value="Administrador">Administrador</option>
                          <option value="Operador">Operador</option>
                          <option value="Proveedor">Proveedor</option>
                   
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['tipo-de-usuario']}
                        </Form.Control.Feedback>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Contraseña<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="password"
                          type="password"
                          id='Constraseña'
                          name='Constraseña'
                          // placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['Constraseña']}
                          isInvalid={!!props.errors['Constraseña']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['Constraseña']}
                        </Form.Control.Feedback>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Estatus<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          as="select"
                          id='estatus'
                          name='estatus'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['estatus']}
                          isInvalid={!!props.errors.estatus}
                        >
                          <option>Selecciona un estatus</option>
                          <option value="Activo">Activo</option>
                          <option value="Inactivo">Inactivo</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.estatus}
                        </Form.Control.Feedback>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Email<span>*</span></Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="email"
                          id='Email'
                          name='Email'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['Email']}
                          isInvalid={!!props.errors.Email}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.Email}
                        </Form.Control.Feedback>
                      </Form.Group>


                    </Form.Row>

                  </div>
                  { edit === true &&
                    <Button className='button primary send-button' type='submit'>
                      <span>Guardar</span>
                    </Button>
                  }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, actions, history, item_id) => {
  try {

    const body = {
      item_id,
      fields: {
        ...values,
        "constrasena":values["Constraseña"],
        "contrasena-ref":values["Constraseña"]
      }
    }
    if (!values.constrasena)
      delete body.fields.constrasena
    console.log(body)
    const response = await Service.editUser(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${USERS_ROUTE}`)
    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}


const mapStateToProps = state => ({
  userData: state.userEdit
})

const mapDispatchToProps = {
  resetState,
}


export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
