import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import UploadLogo from '../../../common/components/uploadLogo'

import './provider.scss';


const Configuration = ({ history }) => {
  const [imageStore, setImageData] = useState('')
  const [data, setData] = useState({})

  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const response = await Service.getConfiguration()
        if (isSuscribed) {
          setData(response.data)
          setImageData(response.data['url-logo'][0]['embed']['url'])
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'No se pudo procesar la solicitud',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <React.Fragment>

      <Container className="form-provider">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className="titulos">Configuración</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "id": data['id'],
                "titulo": data['titulo'],
                "remitente-en-correo": data['remitente-en-correo'],
                "correo-para-recibir-respuestas": data['correo-para-recibir-respuestas'] ? data['correo-para-recibir-respuestas'][0].value : '',
              }}
              onSubmit={(values, actions) => onSubmitForm(values, actions, history, data['item_id'],imageStore)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">

                        <UploadLogo
                          imageStore={imageStore}
                          setImageData={setImageData}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Nombre de empresa</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='titulo'
                          value={props.values['titulo'] ? props.values['titulo'] : ''}
                          placeholder="Escribe la dirección"
                          onChange={props.handleChange}
                        >
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Remitente en correo</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='remitente-en-correo'
                          value={props.values['remitente-en-correo'] ? props.values['remitente-en-correo'] : ''}
                          placeholder="Escribe el remitente-en-correo"
                          onChange={props.handleChange}
                        >
                        </Form.Control>
                        
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Correo para recibir respuestas</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='correo-para-recibir-respuestas'
                          value={props.values['correo-para-recibir-respuestas'] ? props.values['correo-para-recibir-respuestas'] : ''}
                          placeholder="Escribe el nombre del beneficiario"
                          onChange={props.handleChange}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        
                      </Form.Group>
                    </Form.Row>


                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >

  )
}


const onSubmitForm = async (values, actions, history, item_id,imageStore) => {
  try {
    const body = {
      item_id,
      logo_data:imageStore,
      fields: values
    }
    console.log(body)
    const response = await Service.updateConfiguration(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}


export default withRouter(Configuration);
