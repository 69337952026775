import {
    DEBTS_TO_PAT_DATA,
    RESET_STATE
} from '../types'

export const DebtsToPayData = value => (
    {
        type: DEBTS_TO_PAT_DATA,
        payload: value
    }
)

export const resetState = value => (
    {
        type: RESET_STATE
    }
)
