import http from '../../../http'

class LoginService {
    login = async user => {
        return await http.post({
            url: `/login`,
            body: user,
            returnOnError: true,
            login: true
            // showSpinner: true
        })
        
    }

    getLogo = async () => {
        return await http.get({
          url: `/configuracion-logo`,
        })
      }
      
    RecoverPass= async (body) => {
        return await http.post({
        url: `/password-recover`,
        body,
    });
    };
}

export default new LoginService()