import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from '../complements/services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import { COMPLEMENTS_ROUTE } from '../../../routes/types'
import * as Yup from "yup";
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'


// const validationSchema = Yup.object().shape({
//   'no-proveedor': Yup.string().required("Campo requerido"),
//   'enviar-notificacion': Yup.string().email('Invalid email').required("Campo requerido"),
//   'facturas': Yup.number().required("Campo requerido"),
//   'folio': Yup.number().required("Campo requerido"),
//   'monto': Yup.number().required("Campo requerido"),
//   'uuid': Yup.string().required("Campo requerido"),
//   'uuid-relacionado': Yup.string().required("Campo requerido"),
//   'estatus': Yup.string().required("Campo requerido"),
  

// });

const EditComplement = ({ history, complementsData }) => {
  complementsData = JSON.parse(localStorage.getItem('editarComplementos'))
  return (

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${COMPLEMENTS_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">{ complementsData["accion"] === true ? "Editar" : "Consultar" } complemento</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "facturas": complementsData['Cuenta por Pagar'],
                "enviar-notificacion": complementsData['Enviar notificación'],
                "folio": complementsData['Folio'],
                "monto": complementsData['Monto'],
                "uuid": complementsData['UUID'],
                "no-proveedor": complementsData["No. Proveedor"],
                "estatus": complementsData["Estatus"],
                "uuid-relacionado": complementsData["UUID Relacionado"],
                "item_id": complementsData["item_id"],
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, history)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Control 
                      readOnly={!complementsData["accion"]}
                      type="file"
                      id=''
                      name=''
                      placeholder="Escribe el nombre del proveedor"
                      onChange={props.handleChange}
                      value={props.values['']}
                      >

                      </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">No. de proveedor<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          id='no-proveedor'
                          name='no-proveedor'
                          placeholder="Escribe el No. del proveedor"
                          onChange={props.handleChange}
                          value={props.values['no-proveedor']}
                          // pattern="[A-Za-z]{3}" 
                          // title="Three letter country code"
                          required
                          
                          // required
                          // isInvalid={!!props.errors['no-proveedor']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['no-proveedor']}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Enviar notificación<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='enviar-notificacion'
                          name='enviar-notificacion'
                          placeholder="Escribe el email para enviar notificación"
                          onChange={props.handleChange}
                          value={props.values['enviar-notificacion']}
                          // isInvalid={!!props.errors['enviar-notificacion']}
                          required
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['enviar-notificacion']}
                        </Form.Control.Feedback>

                      </Form.Group>
                    </Form.Row>


                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Cuenta por pagar<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          id='facturas'
                          name='facturas'
                          placeholder="Escribe la cuenta por pagar"
                          onChange={props.handleChange}
                          value={props.values['facturas']}
                          pattern="[0,9]{1,20}"
                          title="Ingrese una cuenta bancaria valida. Solo numeros"
                          required
                          // isInvalid={!!props.errors['facturas']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['facturas']}
                        </Form.Control.Feedback>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Folio<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='folio'
                          name='folio'
                          placeholder="ingrese el folio"
                          onChange={props.handleChange}
                          value={props.values['folio']}
                          required
                          // isInvalid={!!props.errors['folio']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['folio']}
                        </Form.Control.Feedback>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Monto<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='monto'
                          name='monto'
                          placeholder="Ingrese el Monto"
                          onChange={props.handleChange}
                          value={montoDecimal(props.values['monto'])}
                          required
                          // isInvalid={!!props.errors['monto']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['monto']}
                        </Form.Control.Feedback>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">UUID<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='uuid'
                          name='uuid'
                          placeholder="ingrese el UUID"
                          onChange={props.handleChange}
                          value={props.values['uuid']}
                          required
                          // isInvalid={!!props.errors['uuid']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['uuid']}
                        </Form.Control.Feedback>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">UUID relacionado<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='uuid-relacionado'
                          name='uuid-relacionado'
                          placeholder="Ingrese el UUID relacionado"
                          onChange={props.handleChange}
                          value={props.values['uuid-relacionado']}
                          required
                          // isInvalid={!!props.errors['uuid-relacionado']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['uuid-relacionado']}
                        </Form.Control.Feedback>

                      </Form.Group>

                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Estatus<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!complementsData["accion"]}
                          as="select"
                          type="text"
                          id='estatus'
                          name='estatus'
                          placeholder="Ingrese el estatus"
                          onChange={props.handleChange}
                          value={props.values['estatus']}
                          required
                          // isInvalid={!!props.errors['estatus']}
                        >
                          <option>Selecciona un estatus</option>
                          <option value="Cancelado">Cancelado</option>
                          <option value="Vigente">Vigente</option>
                          
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['estatus']}
                        </Form.Control.Feedback>

                      </Form.Group>
                    </Form.Row>

                    

                  </div>
                  {
                    complementsData["accion"]=== true &&
                    <Button className='button primary send-button' type='submit'>
                      <span>Guardar</span>
                    </Button>
                  }
                </Form>
                  
              )}
            </Formik>
          </Col>
        </Row>






      </Container>
    </React.Fragment >
  )
}

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  cadena1= '$' + cadena1.split("").reverse().join('')
  return cadena1
          
       
}

const onSubmitForm = async (body, history) => {
  console.log(body)
  try {
    const response = await Service.updateComplement(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${COMPLEMENTS_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}


const mapDispatchToProps = {
  resetState,
}


const mapStateToProps = state => ({
  complementsData: state.editComplements
})

export default connect(mapStateToProps, mapDispatchToProps)(EditComplement)
