import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import { USERS_ROUTE } from '../../../routes/types'
import UploadLogo from '../../../common/components/uploadLogo'


const Configuration = ({ history, match }) => {
  // const [data, setData] = useState([])
  const [imageStore, setImageData] = useState('')

  return (

    <React.Fragment>

      <Container className="form-provider">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className="titulos">Configuración</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "title": '',
                "apellidos": '',
                "email": '',
                "tipo-de-usuario": '',
                "estatus": '',
                "contrasena": '',
              }}
              onSubmit={(values, actions) => onSubmitForm(values, actions, history)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">

                        <UploadLogo
                          imageStore={imageStore}
                          setImageData={setImageData}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre de empresa</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='title'
                          name='title'
                          placeholder="Escribe el nombre"
                          onChange={props.handleChange}
                          value={props.values.title}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Remitente</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='apellidos'
                          name='apellidos'
                          placeholder="Escribe los apellidos"
                          onChange={props.handleChange}
                          value={props.values['apellidos']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Email</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="email"
                          id='email'
                          name='email'
                          placeholder="Escribe el email"
                          onChange={props.handleChange}
                          value={props.values['email']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Tipo de usuario</Form.Label>
                        <Form.Control
                          as="select"
                          id='tipo-de-usuario'
                          name='tipo-de-usuario'
                          onChange={props.handleChange}
                          value={props.values.estado}
                        >
                          <option>Selecciona el tipo</option>
                          <option value="Administrador">Administrador</option>
                          <option value="Proveedor">Proveedor</option>
                          <option value="Operador">Operador</option>
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Estatus</Form.Label>
                        <Form.Control
                          as="select"
                          id='estatus'
                          name='estatus'
                          onChange={props.handleChange}
                          value={props.values.estado}
                        >
                          <option>Selecciona el estatus</option>
                          <option value="Activo">Activo</option>
                          <option value="Inactivo">Inactivo</option>
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Contraseña</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="password"
                          id='contrasena'
                          name='contrasena'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values.contrasena}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, actions, imageStore, history) => {
  try {
    const response = await Service.saveUser(values)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${USERS_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}



export default withRouter(Configuration);
