import http from '../../../../http';


class Service {

  getInvoices = async () => {
    return await http.get({
      url: `/obtener-facturas`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getInvoicesPage = async (body) => {
    return await http.post({
      url: `/obtener-facturas`,
      body
    })
  }

  setStatus = async (body) => {
    return await http.put({
      url: `/manejar-facturas`,
      body
    })
  }

  deleteInvoice = async (body) => {
    return await http.delete({
      url: `/eliminar-factura`,
      body
    })
  }

  editInvoice = async (body) => {
    return await http.put({
      url: `/editar-factura`,
      body
    })
  }

  filterInvoice = async (body) => {
    return await http.post({
      url: `/filtro-facturas`,
      body
    })
  }
}

export default new Service()
