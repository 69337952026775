import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux'
import { PROVIDER_REGISTER_ROUTE, EDIT_PROVIDER_ROUTE } from '../../../routes/types'
import {
  setProviderData
} from '../../owner/editProvider/actions'
import './provider.scss';


const Notificactions = ({ history }) => {
  const perfil = JSON.parse(localStorage.getItem('token')).perfil
    const [data, setData] = useState([])
    const [pageData, setPageData] = useState([])
    const [filtro, setFiltro] = useState([])

  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        const response = await Service.getNotificacion()
        if (isSuscribed) {
          setPageData(response.data['total-resultado'])
          setData(response.data.response)
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'No se pudo procesar la solicitud',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Notificaciones de Cancelación </h1>
        </Col>
      </Row>

      


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                <th width="400px" >UUID</th>
                <th >Estatus Anterior</th>
                <th >Estatus Actual</th>
                <th width="200px">Fecha del Movimiento</th>
                                          
              </tr>
            </thead>
            <tbody>
            {renderList(data, history, perfil, setProviderData)}
            </tbody>
          </Table>
        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, history, perfil, setProviderData,filtro)}
    </Container>

  )
}


const searchBar = (setData,setPageData,setFiltro, body, setBody, results, perfil) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{ buscar: '', column: '' }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData, setPageData, setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option>Selecciona la columna a filtrar</option>
                <option value="uuid">UUID</option>
           
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>

                <Form.Control
                  type="text"
                  name='buscar'
                  onChange={props.handleChange}
                  value={props.values.estado}
                  placeholder="Buscar"
                >
                </Form.Control>

                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData,setPageData,setFiltro) => {
  try {
    const body = {
      [values.column]: values.buscar
    }
    setFiltro(body);
    console.log(body)
    const response = await Service.filterNotificaciones(body)
    if (response.status === 200) {
      setData([response.data])
      setPageData(1)
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }

    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: "success",
      title: error.response.data.dsal.errno[0].mensaje,
      showConfirmButton: false,
      timer: 1500
    })
    console.error(error)
  }

}

const renderList = (notifications, history, perfil, setProviderData) => notifications.map((notification, index) => {
  return <tr key={index}>
    <td>{notification['uuid']}</td>
    <td>{notification['estatus-sat-2']}</td>
    <td>{notification['notificacion']}</td>
    <td>{notification["fecha-cancelacion"]}</td>
  </tr>
})




const mapDispatchToProps = {
  setProviderData,
}


const renderPagination = (data, setData, pageData, history, perfil, setProviderData, filtro) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,history,perfil,setProviderData,filtro)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body, data, setData, history, perfil, setProviderData, filtro) => {
  let response;
  console.log(filtro)
  try {
    if(filtro!==null && filtro.length!==0){
      if(filtro.uuid!==undefined){body={"uuid":filtro.uuid,"pagina":body.pagina}}
      response = await Service.filterNotificaciones(body)
    }
    else{
      response = await Service.getNotificacionesPage(body);
    }
    setData(response.data.response);
    renderList(data, history, perfil, setProviderData)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const aprovar_proveedor = async (item_id, accion) => {
  try {
    const response = await Service.acceptProv({ "item_id":item_id,"acciones":accion})
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

export default connect(null, mapDispatchToProps)(Notificactions);
