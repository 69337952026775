import http from '../../../../http';


class Service {

  getComplements = async () => {
    return await http.get({
      url: `/complementos-de-pago`,
    })
  }

  deleteComplement = async (body) => {
    return await http.delete({
      url: `/eliminar-complemento`,
      body
    })
  }

  updateComplement = async (body) => {
    return await http.put({
      url: `/editar-complemento`,
      body
    })
  }

  filterComplement = async (body) => {
    return await http.post({
      url: `/filtro-complementos`,
      body
    })
  }

}

export default new Service()
