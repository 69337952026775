import http from '../../../../http';


class Service {

  conciliacionGet = async () => {
    return await http.get({
      url: `/conciliacion-sat`,
    })
  }
  conciliacionPost = async (body) => {
    return await http.post({
      url: `/conciliacion-sat`,
      body
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getProviderPage = async (body) => {
    return await http.post({
      url: `/proveedores`,
      body
    })
  }

  filterConciliacion = async (body) => {
    return await http.post({
      url: `/conciliacion-sat-filtro`,
      body
    })
  }

  filterComplements = async (body) => {
    return await http.post({
      url: `/complemento-sat-por-conciliacion`,
      body
    })
  }
}

export default new Service()
