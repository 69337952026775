import React from 'react'
import { Provider } from 'react-redux'
import Loading from './common/components/Loading'
import Routes from './routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import './theme/main.scss'


const App = props => <Provider store={props.store}>
  <Loading force={false} />
  <Routes />
</Provider>

export default App
