import React, { useState, useEffect } from "react";
import Service from './services'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Swal from 'sweetalert2'
import './conteo.scss'
import UploadLogo from '../../../common/components/uploadLogo'
// import { GLOBAL_COUNT } from '../../../routes/types'
const MyData = ({ history, count, setCountData, setCodeData, match }) => {
  const perfil = JSON.parse(localStorage.getItem('token')).perfil
  const [data, setData] = useState({})
  const [title, setTitle] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [password, setPassword] = useState('')
  const [imageStore, setImageData] = useState('')
  const [imageStore1, setImageData1] = useState('')
  const [titulo, setTitulo] = useState('')
  const [email, setEmail] = useState('')
  const [Correo, setCorreo] = useState('')
  const [Correo1, setCorreo1] = useState('')
  const [item_id, setItem_id] = useState('')
  const [archivoCer, setArchivoCer] = useState()
  const [archivoKey, setArchivoKey] = useState()
  const [RFC, setRFC] = useState('')
  const [Contraseña, setContraseña] = useState('')


  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        const response = await Service.getMyData()

        let response1
          if (perfil === "Owner"){
            response1 = await Service.getConfiguration()
          }
          
        if (isSuscribed) {
          setData(response.data)
          setTitle(response.data.title)
          setApellidos(response.data.apellidos)
          setEmail(response.data.email[0].value)
          setPassword(response.data["contrasena-ref"])
          setRFC(response.data.rfc)
          setContraseña(response.data.contrasena)
          if(perfil === "Owner"){
            setData(response1.data)
            setImageData(response1.data['url-logo'][0]['embed']['url'])
            setImageData1(response1.data['url-logo-conf'][0]['embed']['url'])
            setTitulo(response1.data.titulo)
            setCorreo(response1.data['remitente-en-correo'])
            setCorreo1(response1.data['correo-para-recibir-respuestas'][0].value)
            setItem_id(response1.data.item_id)

          }
          
          
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Se produjo un error en la peticion',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])

  

  return (

    <div className="wrapper__conteo">
      <Container className="form-provider">
        {
          perfil==="Owner" &&
          <div className="mt-2 mb-2">
          <h1 className="titulos">Datos</h1>
        </div>

        }
        {
          perfil ==="Proveedor"  &&
          <div className="mt-2 mb-2">
          <h1 className="titulos">Mi usuario</h1>
          </div>
        }
        {
          perfil==="Administrador"&&
          <div className="mt-2 mb-2">
          <h1 className="titulos">Mis datos</h1>
        </div>
        }
        {
          perfil==="Operador"&&
          <div className="mt-2 mb-2">
          <h1 className="titulos">Mis datos</h1>
        </div>
        }
        
        <div className="container ">
          <div className="row">
            <div className="col-md-6 expand-title">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row ">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Nombre<span>*</span></label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="text"
                        required
                        className="form-control "
                        // placeholder="Escribe el ID del usuario"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mt-1">
                  <label htmlFor="term" className="col-sm-6 col-form-label bg-primary">Apellidos<span>*</span></label>
                  <div className="col-sm-6 col-form-control" >
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Escribe la terminal"
                      value={apellidos}
                      onChange={(e) => setApellidos(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Email</label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="text"
                        readOnly={true}
                        className="form-control"
                        // placeholder="Escribe el ID del usuario"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mt-1">
                  <label htmlFor="term" className="col-sm-6 col-form-label bg-primary">Tipo de usuario</label>
                  <div className="col-sm-6 col-form-control">
                    <input
                      type="text"
                      className="form-control"
                      readOnly={true}
                      placeholder="Escribe la terminal"
                      value={data['tipo-de-usuario']}
                    />
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="wrapper_input_bg">
                <div className="form-group row">
                  <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Contraseña<span>*</span></label>
                  <div className="col-sm-6 col-form-control">
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="Contrasena"
                      // placeholder="Escribe el ID del usuario"
                      value={password}
                      // onChange={props.handleChange}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

              </div>
            </div>


            <div className="col-md-6">
              <div className=" w-100">
               
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <br></br>
          {
            perfil === "Owner" &&
          
          <div className="text-center">
            <button
              onClick={() => updateProfile(title, apellidos, password)}
              className="btn btn-primary"
            >

              Guardar
          </button>
          </div>
          }
          {
            perfil !== "Owner" &&
          
          <div className="text-center">
            <button
              onClick={() => updateProfile(title, apellidos, password)}
              className="btn btn-primary"
            >

              Guardar
          </button>
          </div>
          }
        </div >
        {
          perfil === "Owner" &&
        
        <div>
          <div className="mt-2 mb-2">
            <h1 className="titulos">Configuración</h1>
          </div>
          
          
          <div className="container ">
            
          <div class="row mb-3">
          <div class="col-6">
          <UploadLogo
            imageStore={imageStore1}
            setImageData={setImageData1}
          />
          </div>
          <div class="col-6">
          <UploadLogo
            imageStore={imageStore}
            setImageData={setImageData}
            titulo="Logotipo firma"
          />
          </div>
          </div>
        
          <div className="row">
            <div className="col-md-6 expand-title">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row ">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Nombre de empresa<span>*</span></label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="text"
                        required
                        className="form-control "
                        // placeholder="Escribe el ID del usuario"
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mt-1">
                  <label htmlFor="term" className="col-sm-6 col-form-label bg-primary">Remitente en correo<span>*</span></label>
                  <div className="col-sm-6 col-form-control">
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="remitente-en-correo"
                      placeholder="Escribe la terminal"
                      value={Correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Correo para recibir respuestas<span>*</span></label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="text"
                        required
                        readOnly={true}
                        className="form-control"
                        // placeholder="Escribe el ID del usuario"
                        value={Correo1}
                        onChange={(e) => setCorreo1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() => updateConfiguracion(titulo, Correo, Correo1, item_id, imageStore, imageStore1)}
              className="btn btn-primary"
            >

              Guardar
            </button>
          </div>

          <div className="mt-2 mb-2">
            <h1 className="titulos">Conexión al SAT</h1>
          </div>
          <div>
          <Form>
              <div>
                <Form.Row className="register-form-row justify-content-center">
                  <Form.Group md="4">
                    <Form.Label className="label-provider">Cargue su archivo cer de la FIEL<span> *</span></Form.Label>
                    <Form.File
                      className=".inputs-info"
                      type="file"
                      required
                      id='cer'
                      name='cer'
                      accept=".cer"
                      onChange={(event) => {
                        setArchivoCer(event.currentTarget.files[0]);
                      }}
                    >
                    </Form.File>
                  </Form.Group>
                </Form.Row>
              </div>
            </Form>
          
          </div>
          <br></br>
          <br></br>
            <Form>
              <div>
                <Form.Row className="register-form-row justify-content-center">
                  <Form.Group md="4">
                    <Form.Label className="label-provider">Cargue su archivo key de la FIEL<span> *</span></Form.Label>
                    <Form.File
                      className=".inputs-info"
                      type="file"
                      required
                      id='key'
                      name='key'
                      accept=".key"
                      onChange={(event) => {
                        setArchivoKey(event.currentTarget.files[0]);
                      }}
                    >
                    </Form.File>
                  </Form.Group>
                </Form.Row>
              </div>
            </Form>
          <br></br>
          <div className="row">
            <div className="col-md-6 expand-title">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row ">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">RFC FIEL<span>*</span></label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="text"
                        required
                        className="form-control "
                        placeholder="Ingrese el RFC del proveedor"
                        value={RFC}
                        onChange={(e) => setRFC(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper_input_bg">
                <div className="expand-title">
                  <div className="form-group row">
                    <label htmlFor="id_user" className="col-sm-6 col-form-label bg-primary">Contraseña FIEL<span>*</span></label>
                    <div className="col-sm-6 col-form-control">
                      <input
                        type="password"
                        required
                        className="form-control"
                        placeholder="Ingrese la contraseña"
                        value={Contraseña}
                        onChange={(e) => setContraseña(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
          {/* <div>
          <input type="file" accept=".key" name="uploadedfile"
          onChange={(e) => setArchivoKey(e.target.value)}
          />
          </div> */}
          
          <br></br>
          <br></br>
          
          <div className="text-center">
            <button
              onClick={() => updateArchivos(archivoCer, archivoKey,RFC,Contraseña)}
              className="btn btn-primary"
            >

              Guardar
            </button>
          </div>
        </div >
      </div >
}
        
        
       
      </Container>
    </div>

  )
};

const updateConfiguracion = async (titulo, Correo, Correo1, item_id, imageStore, imageStore1) => {
  try {
    const body = {
      item_id,
      logo_data:imageStore,
      logo_data1: imageStore1,
      fields:{
      titulo,
      "remitente-en-correo": Correo,
      "correo-para-recibir-respuestas": Correo1
      }
      // fields: {
      //   title,
      //   apellidos,
      //   password,
      // }
    }
    const response = await Service.updateMyData_Conf(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}


// const updateArchivos= async ( archivosCer, archivoKey,RFC,Contraseña) => {
//   try {

//     const archivos = new FormData()
//     archivos.append('cer',archivosCer);
//     archivos.append('key',archivoKey);
//     let response;
//     response = await Service.uploadFiles(archivos);
//     // const body = {
//     //   item_id,
//     //   logo_data:imageStore,
//     //   logo_data1: imageStore1,
//     //   fields:{
//     //   titulo,
//     //   "remitente-en-correo": Correo,
//     //   "correo-para-recibir-respuestas": Correo1
//     //   }
//     //   // fields: {
//     //   //   title,
//     //   //   apellidos,
//     //   //   password,
//     //   // }
//     // }
    
//     if (response.status === 200) {
//       Swal.fire({
//         icon: 'success',
//         text: 'Proceso correcto',
//         showConfirmButton: false,
//         timer: 1500
//       })
//     }
//   } catch (error) {
//     Swal.fire({
//       icon: 'warning',
//       title: 'No se pudo procesar la solicitud',
//       confirmButtonColor: '#e35205',
//       confirmButtonText: '<i class="fa fa-check"></i>',
//       customClass: {
//         popup: 'elimcontent',
//         closeButton: 'closelimn',
//         icon: 'iconelim',
//         confirmButton: 'checkswal',
//       }
//     })
//     console.error(error);
//   }
// }


const updateArchivos = async (archivosCer, archivoKey,RFC,Contraseña) => {
  try {
    // const configuracion = {
    //   item_id,
    //   logo_data:imageStore,
    //   logo_data1: imageStore1,
    //   fields:{
    //     titulo,
    //     "remitente-en-correo": Correo,
    //     "correo-para-recibir-respuestas": Correo1
    //   }
    // }
    // const profile = {
    //     fields: {
    //     title,
    //     apellidos,
    //     password,
    //     "rfc":RFC,
    //     "contrasena":Contraseña
    //   }
    // }
    const body = {
      fields:{
        "rfc":RFC,
        "contrasena":Contraseña

      }
    }
    console.log(JSON.stringify(body))
    const archivos = new FormData()
    archivos.append('cer',archivosCer);
    archivos.append('key',archivoKey);
    let response;
    response = await Service.updateData(body)
    response = await Service.uploadFiles(archivos);
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}


const updateProfile = async (title, apellidos, password) => {
  try {
    const body = {
      fields: {
        title,
        apellidos,
        password,
        "contrasena-ref":password
      }
    }
    const response = await Service.updateData(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}






export default MyData