import {
  SET_INVOICES_DATA,
  RESET_STATE
} from '../types'

const defaultState = {

}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_INVOICES_DATA:
      return {
        ...state,
        ...action.payload
      }

    case RESET_STATE:
      return {
        ...defaultState
      }
    default:
      return state

  }
}
