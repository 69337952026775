import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination';
import {EDIT_COMPLEMENT_ROUTE,UPLOAD_COMPLEMENT_ROUTE} from '../../../routes/types'
import {setComplementsData} from '../../owner/editComplement/actions'
import { connect } from 'react-redux'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

const Complements = ({history,setComplementsData,filterComplementsData}) => {
  const perfil = JSON.parse(localStorage.getItem('token')).perfil
  const dataUser = JSON.parse(localStorage.getItem('token'))
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState([])
  const [filtro, setFiltro] = useState([])
  useEffect(() => {
    let isSuscribed = true
    let response;
    const getData = async () => {
      try {
        if(Object.entries(filterComplementsData).length === 0){
          const respuesta = await Service.getLogo()
          localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
          response = await Service.getComplements()
          
        }else{
          const body = {
            facturas : filterComplementsData["item_id"]
          }
          response = await Service.filterComplement(body)
        }
        if (isSuscribed) {
          setData(response.data.response)
          setPageData(response.data['total-resultado'])
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Se produjo un error en la peticion',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        {
          perfil ==='Administrador' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis complementos</h1>
          </Col>

        }
        {
          perfil ==='Proveedor' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis complementos</h1>
          </Col>

        }
        {
          perfil ==='Operador' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis complementos</h1>
          </Col>

        }
        {
          perfil ==='Owner' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Complementos</h1>
          </Col>
        }
        
        {
        perfil === 'Proveedor' &&
        
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <ButtonToolbar className="justify-content-center margin-withtable  mb-1">
              <Button variant="primary" type="submit" onClick={() => UploadComplements(history,dataUser,data)}>
                subir complemento
                  </Button>
            </ButtonToolbar>
          </Col>
       
      }
      </Row>


      


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                {
                  perfil !=="Proveedor" &&
                  <th>Nombre proveedor</th>
                }
                
                <th>Folio</th>
                <th>Monto</th>
                <th>UUID</th>
                <th>Estatus</th>
                {
                  perfil !=="Proveedor" &&
                  <th>Acciones</th>
                }
                <th>Archivos</th>
              </tr>
            </thead>
            <tbody>
              {renderList(data,history,setComplementsData, perfil)}
            </tbody>
          </Table>

          {/* <PaginationController
            paginacion={data.dsal.paginacion && data.dsal.paginacion[0]}
            setData={setData}
            getPage={Service.getListApplied}
            num_results={results}
            body={body}
            setResults={setResults}
          /> */}

        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, history,filtro,setComplementsData)}
    </Container>

  )
}


const searchBar = (setData,setPageData,setFiltro) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{ buscar: '', column: '' }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData,setPageData,setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option>Selecciona la columna a filtrar</option>
                <option value="proveedor">Proveedor</option>
                <option value="folio">Folio</option>
                <option value="uuid">UUID</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  type="text"
                  name='buscar'
                  value={props.values.buscar}
                  onChange={props.handleChange}
                  placeholder="Buscar" />
                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData,setPageData,setFiltro) => {
  try {
    const body = {
      [values.column]: values.buscar
    }
    //console.log(JSON.stringify(body))
    setFiltro(body);
    const response = await Service.filterComplement(body)
    if (response.status === 200) {
      setData(response.data.response)
      setPageData(response.data["total-resultado"])
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: "success",
      title: error.response.data.dsal.errno[0].mensaje,
      showConfirmButton: false,
      timer: 1500
    })
    console.error(error)
  }

}

const renderList = (complements, history, setComplementsData, perfil) => complements.map((complement, index) => {
  return <tr key={index}>
    {
      perfil!=="Proveedor" &&
      <td>{complement['No. Proveedor']}</td>
    }
    
    <td>{complement['Folio']}</td>
    {montoDecimal(complement['Monto'])}
    <td>{complement['UUID']}</td>
    <td>{complement['Estatus']}</td>
    {
      perfil !=="Proveedor" &&
      <td className="box_icons_tables">
        {
          ((perfil !="Administrador") && (perfil != "Operador"))  &&
          <i
          onClick={() => deleteComp(complement['item_id'])}
          className="fas fa-trash i_icons">
          </i>
        }
     
      <i
        onClick={() => assignData(complement,setComplementsData,history,true)}
        className="fas fa-pen i_icons">
      </i>
      <i
        onClick={() => assignData(complement,setComplementsData,history,false)}
        className="fas fa-eye i_icons">
      </i>
    </td>
    }
    
    <td className="box_icons_tables">
    <a className="url-send" href={complement["url-pdf"]}target="_blak">
        <i
          className="fas fa-file-pdf i_icons">
        </i>
      </a>
      <a className="url-send" href={complement["url-xml"]}target="_blak">
        <i
          className="fas fa-file i_icons">
        </i>
      </a>
    </td>
  </tr>
})

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  return <td>
    <Form>
      <Form.Row>
        <Form.Group as={Col} md="2">
          {'$'}
        </Form.Group>
        <Form.Group as={Col} md="10">
          {cadena1.split("").reverse().join('')}
        </Form.Group>
      </Form.Row>
    </Form>
  </td>
}

const assignData = (complement, setComplementsData, history,accion) => {
  const body = {
    "ID":complement['ID'],
    "No. Proveedor":complement['No. Proveedor'],
    "Enviar notificación":complement['Enviar notificación'][0].value,
    "Cuenta por Pagar":complement['Cuenta por Pagar'],
    "Folio":complement['Folio'],
    "Monto":complement['Monto'],
    "UUID":complement['UUID'],
    "UUID Relacionado":complement['UUID Relacionado'],
    "Estatus":complement['Estatus'],
    "item_id":complement['item_id'],
    "accion":accion
  }
  localStorage.setItem('editarComplementos', JSON.stringify(body))
  setComplementsData(body)
  history.push(`/app${EDIT_COMPLEMENT_ROUTE}`)
}

// const editComplement = async (values, actions, item_id, field) => {
//   try {
//     const body = {
//       item_id,
//       fields: {
//         [field]: values[field]
//       }
//     }
//     const response = await Service.updateComplement({})
//     if (response.status === 200) {
//       Swal.fire({
//         icon: 'success',
//         text: 'Proceso correcto',
//         showConfirmButton: false,
//         timer: 1500
//       })
//     }
//   } catch (error) {
//     Swal.fire({
//       icon: 'warning',
//       title: 'No se pudo procesar la solicitud',
//       confirmButtonColor: '#e35205',
//       confirmButtonText: '<i class="fa fa-check"></i>',
//       customClass: {
//         popup: 'elimcontent',
//         closeButton: 'closelimn',
//         icon: 'iconelim',
//         confirmButton: 'checkswal',
//       }
//     })
//     console.error(error);
//   }
// }

const deleteComp = async (item_id) => {
    const body = {
      'item_id':item_id
    }
    
  try {
    const response = await Service.deleteComplement(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const renderPagination = (data,setData,pageData, history,filtro,setComplementsData) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,history,filtro,setComplementsData)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body,data,setData,history,filtro,setComplementsData) => {
  let response;
  try {
    if(filtro.proveedor!==null){
      if(filtro.proveedor!==undefined){body={"proveedor":filtro.proveedor,"pagina":body.pagina}}
      response = await Service.filterComplement(body);

    }else{
      response = await Service.getComplementsPage(body);
    }
    setData(response.data.response);
    renderList(data,history,setComplementsData)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const UploadComplements = (history,dataUser,data) => {
  history.push(`/app${UPLOAD_COMPLEMENT_ROUTE}`)
}


const mapDispatchToProps = {
  setComplementsData,
}

const mapStateToProps = state => ({
  filterComplementsData: state.filterComplements
})

export default connect(mapStateToProps,mapDispatchToProps)(Complements);