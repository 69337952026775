import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import * as Yup from "yup";
import { PROVIDER_ROUTE } from '../../../routes/types'
import UploadLogo from '../../../common/components/uploadLogo'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'

// const validationSchema = Yup.object().shape({
//   'proveedor': Yup.string().required("Campo requerido"),
//   'id-proveedor-sap': Yup.number().required("Campo requerido"),
//   'razon-social': Yup.string().required("Campo requerido"), 
//   'rfc': Yup.string().required("Campo requerido"),
//   'politica-de-pago2': Yup.number().required("Campo requerido"),
//   'estatus': Yup.string().required("Campo requerido"),
  

// });


const EditProvider = ({ history, match, providerData }) => {
  const [imageStore, setImageData] = useState('')
  providerData = JSON.parse(localStorage.getItem('editarProveedor'))
  const edit = providerData["accion"]
  providerData = providerData["user"]
  
  return (
    // <div className="provider-wrapper">

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${PROVIDER_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">{edit === true ? "Editar" : "Consultar"} proveedor</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                // proveedor: '',
                // rfc: '',
                "proveedor": providerData['proveedor'],
                "id-proveedor-sap": providerData['id-proveedor-sap'],
                "razon-social": providerData['razon-social'],
                "rfc": providerData['rfc'],
                "politica-de-pago2": providerData['politica-de-pago2'],
                "estatus": providerData['estatus'],
                "cuenta-bancaria": providerData["cuenta-bancaria"],
                "nombre-de-beneficiario": providerData["nombre-de-beneficiario"],
                "banco": providerData["banco"],
                "direccion-fiscal": providerData["direccion-fiscal"],
                "email-de-contacto": providerData["email-de-contacto"][0]["value"],
                "pagina-web": providerData["pagina-web"][0]["embed"].url,
                "usuarios":providerData["usuarios"]["title"],
                
                
                
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, actions, imageStore, history, providerData['item_id'])}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">

                        <UploadLogo
                          imageStore={providerData['Logo']}
                          setImageData={setImageData}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre de proveedor<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='proveedor'
                          name='proveedor'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['proveedor']}
                          required
                          // isInvalid={!!props.errors.proveedor}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.proveedor}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Id proveedor interno<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='id-proveedor-sap'
                          name='id-proveedor-sap'
                          placeholder="Escribe el Id del proveedor interno"
                          onChange={props.handleChange}
                          value={props.values['id-proveedor-sap']}
                          required
                          // isInvalid={!!props.errors['id-proveedor-sap']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['id-proveedor-sap']}
                        </Form.Control.Feedback> */}


                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Razón social<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='razon-social'
                          name='razon-social'
                          placeholder="Escribe la razón social"
                          onChange={props.handleChange}
                          value={props.values['razon-social']}
                          required
                          // isInvalid={!!props.errors['razon-social']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['razon-social']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Estatus<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          as="select"
                          type="text"
                          id='estatus'
                          name='estatus'
                          placeholder="Selecciona el estatus del proveedor"
                          onChange={props.handleChange}
                          value={props.values['estatus']}
                          required
                          // isInvalid={!!props.errors['estatus']}
                        >
                          <option>Selecciona un estatus</option>
                          <option value="Pendiente">Pendiente</option>
                          <option value="Activo">Activo</option>
                          <option value="Inactivo">Inactivo</option>
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['estatus']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                    </Form.Row>


                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">RFC<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='rfc'
                          name='rfc'
                          placeholder="Escribe el RFC"
                          onChange={props.handleChange}
                          value={props.values.rfc}
                          required
                          // isInvalid={!!props.errors.rfc}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.rfc}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Política de pago<span> *</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='politica-de-pago2'
                          name='politica-de-pago2'
                          placeholder="Escribe la política de pago"
                          onChange={props.handleChange}
                          value={props.values['politica-de-pago2']}
                          pattern="[0-9]{1,2}"
                          title="La politica de pago solo permite ingresar dos digitos"
                          required
                          // isInvalid={!!props.errors['politica-de-pago2']}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors['politica-de-pago2']}
                        </Form.Control.Feedback>

                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Clabe o cuenta bancaria</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='cuenta-bancaria'
                          name='cuenta-bancaria'
                          placeholder="Escribe la cuenta bancaria"
                          onChange={props.handleChange}
                          value={props.values['cuenta-bancaria']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre de beneficiario</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='nombre-de-beneficiario'
                          name='nombre-de-beneficiario'
                          placeholder="Escribe el nombre del beneficiario"
                          onChange={props.handleChange}
                          value={props.values['nombre-de-beneficiario']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Banco</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='banco'
                          name='banco'
                          placeholder="Escribe el banco"
                          onChange={props.handleChange}
                          value={props.values['banco']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Dirección fiscal</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='direccion-fiscal'
                          name='direccion-fiscal'
                          placeholder="Escribe la direccion fiscal"
                          onChange={props.handleChange}
                          value={props.values['direccion-fiscal']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Email de contacto</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='email-de-contacto'
                          name='email-de-contacto'
                          placeholder="Escribe el email"
                          onChange={props.handleChange}
                          value={props.values['email-de-contacto']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Página web</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='pagina-web'
                          name='pagina-web'
                          placeholder="Escribe la página web"
                          onChange={props.handleChange}
                          value={props.values['pagina-web']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                    <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Usuarios</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='usuarios'
                          name='usuarios'
                          placeholder="Escribe el nombre del usuario"
                          onChange={props.handleChange}
                          value={props.values['usuarios']}
                        >
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                   
                  </div>
                  {
                    edit === true &&
                    <Button className='button primary send-button' type='submit'>
                      <span>Guardar</span>
                    </Button>
                  }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>






      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, actions, imageStore, history, item_id) => {
  try {
    values.logo_data = imageStore
    const body = {
      item_id,
      fields: {
        ...values
      }
    }
    const response = await Service.updateProvider(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${PROVIDER_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}

const mapStateToProps = state => ({
  providerData: state.editProvider
})

const mapDispatchToProps = {
  resetState,
}



export default connect(mapStateToProps, mapDispatchToProps)(EditProvider);
