import http from '../../../../http';


class Service {

  editInvoice = async (body) => {
    return await http.put({
      url: `/actualizar-factura`,
      body
    })
  }
}

export default new Service()
