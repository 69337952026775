import axios from 'axios'
import { emitter } from './common/components/Loading'


//const endpoint = 'http://localhost:5000'
const endpoint = 'https://cfdicatcher.dsindigo.com.mx:8442'


if (endpoint)
  console.log(`Using ${endpoint} endpoint...`)

class HTTPService {

  get = async ({ url, queryParams, body, showSpinner = true, validateAuthentication = true, history = undefined, login = false }) => {
    if (showSpinner)
      emitter.emit('loading', true)
    if (!login)
      body = this.setAuthToken(body)
    try {
      if (!url.includes('http') && url.startsWith('/'))
        url = `${endpoint}${url}`
      const response = await axios.get(this.createURL(url, '', queryParams))
      emitter.emit('loading', false)
      return response
    } catch (error) {
      const err = error
      if (validateAuthentication || error.request)
        this.verifyRequestStatus({ status: error.request.status, history })
      emitter.emit('loading', false)
      throw err
    }
  }

  post = async ({ url, queryParams, body, showSpinner = true, validateAuthentication = true, history = undefined, login = false }) => {
    if (showSpinner) {
      emitter.emit('loading', true)
    }
    if (!login)
      body = this.setAuthToken(body)
    try {
      if (!url.includes('http') && url.startsWith('/'))
        url = `${endpoint}${url}`
      const response = await axios.post(this.createURL(url, '', queryParams), body)
      emitter.emit('loading', false)
      return response
    } catch (error) {
      const err = error
      if (validateAuthentication || error.request)
        this.verifyRequestStatus({ status: error.request.status, history })
      emitter.emit('loading', false)
      throw err
    }
  }


  put = async ({ url, id, body, showSpinner = true, validateAuthentication = true, history = undefined, login = false }) => {
    if (showSpinner) {
      emitter.emit('loading', true)
    }
    if (!login)
      body = this.setAuthToken(body)
    try {
      if (!url.includes('http') && url.startsWith('/'))
        url = `${endpoint}${url}`
      const response = await axios.put(this.createURL(url, id), body)
      emitter.emit('loading', false)
      return response
    } catch (error) {
      const err = error
      if (validateAuthentication || error.request)
        this.verifyRequestStatus({ status: error.request.status, history })
      emitter.emit('loading', false)
      throw err
    }
  }

  // patch = async ({ url, id, body, showSpinner = true, validateAuthentication = true, history = undefined }) => {
  //   if (showSpinner)
  //     // emitter.emit('loading', true)
  //     this.setAuthToken()
  //   try {
  //     if (!url.includes('http') && url.startsWith('/'))
  //       url = `${endpoint}${url}`
  //     const response = await axios.patch(this.createURLPatch(url, id), body)
  //     // emitter.emit('loading', false)
  //     return response
  //   } catch (error) {
  //     const err = error
  //     if (validateAuthentication || error.request)
  //       this.verifyRequestStatus({ status: error.request.status, history })
  //     // emitter.emit('loading', false)
  //     throw err
  //   }
  // }

  delete = async ({ url, id, body, showSpinner = true, validateAuthentication = true, history = undefined, login = false }) => {
    if (showSpinner)
      emitter.emit('loading', true)
    if (!login)
      body = this.setAuthToken(body)
    try {
      if (!url.includes('http') && url.startsWith('/'))
        url = `${endpoint}${url}`
      const response = await axios.delete(this.createURL(url, id), { data: body })
      emitter.emit('loading', false)
      return response
    } catch (error) {
      const err = error
      if (validateAuthentication || error.request)
        this.verifyRequestStatus({ status: error.request.status, history })
      emitter.emit('loading', false)
      throw err
    }
  }

  createURL = (url, id = '', queryParams) => {
    const listOfParams = this.objectToList(queryParams)
    try {
      const urlValidated = new URL(this.formatIdParameter(id), url)
      if (queryParams) {
        for (const params of listOfParams)
          urlValidated.searchParams.append(params[0], params[1])
      }
      return urlValidated.href
    } catch (error) {
      const err = error
      emitter.emit('loading', false)
      throw err
    }
  }

  formatIdParameter = id => {
    if (this.hasTrailingSlash(id))
      return id
    return `${id}/`
  }

  hasTrailingSlash = id => {
    if (!id)
      return true
    const stringIdList = id.toString().split('/')
    return !stringIdList[stringIdList.length - 1] === ''
  }

  objectToList = obj => {
    try {
      const listOfParams = []
      for (const key in obj)
        listOfParams.push([key, obj[key]])
      return listOfParams
    } catch (error) {
      const err = error
      emitter.emit('loading', false)
      throw err
    }
  }

  createURLPatch = (url, id = '') => {
    const urlValidated = new URL(id, url)
    return `${urlValidated.href}/`
  }

  verifyRequestStatus = ({ status, history }) => {
    if (status === 401) {
      console.error('Not authenticated!')
      localStorage.clear()
      if (history !== undefined)
        history.replace('/')
      else
        window.location.href = '/'
    }
  }

  setAuthToken = (body) => {
    const token = JSON.parse(localStorage.getItem('token'))
    if (token) {
      axios.defaults.headers.Authorization = `Bearer ${token.access_token}`
    }
    else
      axios.defaults.headers.Authorization = ''

    return body
  }

}

export default new HTTPService()
