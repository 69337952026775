import http from '../../../../http';


class Service {

  getConfiguration = async () => {
    return await http.get({
      url: `/configuracion-de-cuenta`,
    })
  }

  updateConfiguration = async (body) => {
    return await http.put({
      url: `/editar-configuracion`,
      body
    })
  }


}

export default new Service()
