import http from '../../../../http';


class Service {

  getSATinvoices = async () => {
    return await http.get({
      url: `/obtener-facturas-sat-recibidos`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getSATinvoicesPage = async (body) => {
    return await http.post({
      url: `/obtener-facturas-sat-recibidos`,
      body
    })
  }
  

  filterSATinvoices = async (body) => {
    return await http.post({
      url: `/obtener-facturas-sat-filtro-recibidos`,
      body
    })
  }
}

export default new Service()
