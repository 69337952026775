import {
    COMPLEMENTS_DATA_SAT,
    RESET_STATE
} from '../types'

export const ComplementsDataSat = value => (
    {
        type: COMPLEMENTS_DATA_SAT,
        payload: value
    }
)

export const resetState = value => (
    {
        type: RESET_STATE
    }
)
