import React, {useState} from 'react'
import { withRouter } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {
  PROVIDER_ROUTE,
  DEBTS_TO_PAY_ROUTE,
  GET_INVOICES_ROUTE,
  COMPLEMENTS_ROUTE,
  MY_DATA_ROUTE
} from '../../../../routes/types'
import './menugeneral.scss'

const MenuGeneral = ({ history, match }) => {
  
  const [owner,setOwner] = useState('')
  const [url,setUrl] = useState('')
  let path = window.location;
  path = path.href
  if(owner==="" || owner===null){
    if (path.indexOf("app/mis-datos")>-1){
        setOwner(['colorSeleccion','','','',''])

    } else if (path.indexOf("app/proveedores")>-1 || 
                path.indexOf("app/editar-proveedor")>-1 || 
                  path.indexOf("app/crear-proveedor")>-1 ||
                    path.indexOf("app/registrar-proveedor-usuario")>-1){
                      setOwner(['','colorSeleccion','','',''])

    } else if (path.indexOf("app/cuentas-por-pagar")>-1 || 
                path.indexOf("app/editar-cuentas-por-pagar")>-1) {
                  setOwner(['','','colorSeleccion','',''])

    } else if (path.indexOf("app/complementos-pago")>-1 || 
                path.indexOf("app/editar-complemento")>-1) {
                  setOwner(['','','','colorSeleccion',''])
                  
    } else if (path.indexOf("app/obtener-facturas")>-1 || 
                path.indexOf("app/editar-facturas-recibidas")>-1) {
                  setOwner(['','','','','colorSeleccion'])
    }
    setTimeout(function(){ refresh(setUrl) }, 2000);
  }


  return (
    <Container fluid={true} className="margin-navbar">
      <Navbar bg="primary" expand="lg" variant="dark" className="menu-sucursal">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          <img src={url}  width="92" height="82"/>
            <Nav.Link className={owner[0]} onClick={() => cambiarColor(setUrl,setOwner,0,history,`${match.path}${MY_DATA_ROUTE}`)}><i className="fas fa-user-circle g_icons"></i> Mis datos</Nav.Link>
            <Nav.Link className={owner[1]} onClick={() => cambiarColor(setUrl,setOwner,1,history,`${match.path}${PROVIDER_ROUTE}`)}><i className="fas fa-truck g_icons"></i> Mis proveedores</Nav.Link>
            <Nav.Link className={owner[2]} onClick={() => cambiarColor(setUrl,setOwner,2,history,`${match.path}${DEBTS_TO_PAY_ROUTE}`)}><i className="fas fa-file-invoice-dollar g_icons"></i> Mis cuentas por pagar</Nav.Link>
            <Nav.Link className={owner[3]} onClick={() => cambiarColor(setUrl,setOwner,3,history,`${match.path}${COMPLEMENTS_ROUTE}`)}><i className="fas fa-file-alt g_icons"></i> Mis complementos</Nav.Link>
            <Nav.Link className={owner[4]} onClick={() => cambiarColor(setUrl,setOwner,4,history,`${match.path}${GET_INVOICES_ROUTE}`)}><i className="fas fa-file-invoice g_icons"></i> Mis facturas recibidas</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}


const cambiarColor = (setUrl,setOwner,number,history,ruta) =>{
  setTimeout(function(){ setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"]) }, 2000);
  if(number===0){
    setOwner(['colorSeleccion','','','','',''])
  } else if(number===1) {
    setOwner(['','colorSeleccion','','','',''])
  } else if(number===2) {
    setOwner(['','','colorSeleccion','','',''])
  } else if(number===3) {
    setOwner(['','','','colorSeleccion','',''])
  } else if(number===4) {
    setOwner(['','','','','colorSeleccion',''])
  } else {
    setOwner(['','','','','','colorSeleccion'])
  }
  history.push(ruta)
}

const refresh = async (setUrl)=>{

  setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"])
  console.log("se mando a llamar")

}

export default withRouter(MenuGeneral)