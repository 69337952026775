import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import * as Yup from "yup";
import Button from 'react-bootstrap/Button'
import { PROVIDER_DATA_ROUTE } from '../../../routes/types'


const ProviderData = ({ history, match }) => {
  const [data, setData] = useState({})
  const [item_id, setItemId] = useState('')
  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const response = await Service.getProviderData()
        if (isSuscribed) {
          if (response.data.msg!==undefined){
            Swal.fire({
              icon: 'warning',
              title: response.data.msg,
              confirmButtonColor: '#e35205',
              confirmButtonText: '<i class="fa fa-check"></i>',
              customClass: {
                popup: 'elimcontent',
                closeButton: 'closelimn',
                icon: 'iconelim',
                confirmButton: 'checkswal',
              }
            })
          }
          else{
            setData(response.data)
            setItemId(response.data['item_id'])

          }
          
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Se produjo un error en la peticion',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])


  return (

    <React.Fragment>

      <Container className="form-provider">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className="titulos">Mis Datos</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "id-proveedor-sap": data['id-proveedor-sap'],
                "direccion-fiscal": data['direccion-fiscal'],
                "banco": data['banco'],
                "nombre-de-beneficiario": data['nombre-de-beneficiario'],
                "cuenta-bancaria": data['cuenta-bancaria'],
                "rfc": data['rfc'],
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, actions, item_id, history, setData)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">

                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Id de proveedor interno</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          readOnly="true"
                          type="text"
                          name='id-proveedor-sap'
                          value={props.values['id-proveedor-sap'] ? props.values['id-proveedor-sap'] : ''}
                          placeholder="Escribe el id"
                          onChange={props.handleChange}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">RFC<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='rfc'
                          value={props.values['rfc'] ? props.values['rfc'] : ''}
                          placeholder="Escribe la dirección"
                          onChange={props.handleChange}
                          required
                          // isInvalid={!!props.errors.rfc}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.rfc}
                        </Form.Control.Feedback> */}
                        
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Dirección fiscal<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='direccion-fiscal'
                          value={props.values['direccion-fiscal'] ? props.values['direccion-fiscal'] : ''}
                          placeholder="Escribe la dirección"
                          onChange={props.handleChange}
                          required
                          // isInvalid={!!props.errors['direccion-fiscal']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['direccion-fiscal']}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Banco<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='banco'
                          value={props.values['banco'] ? props.values['banco'] : ''}
                          placeholder="Escribe el banco"
                          onChange={props.handleChange}
                          required
                          // isInvalid={!!props.errors.banco}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.banco}
                        </Form.Control.Feedback> */}
                        
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Nombre de beneficiario<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='nombre-de-beneficiario'
                          value={props.values['nombre-de-beneficiario'] ? props.values['nombre-de-beneficiario'] : ''}
                          placeholder="Escribe el nombre del beneficiario"
                          onChange={props.handleChange}
                          required
                          // isInvalid={!!props.errors['nombre-de-beneficiario']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['nombre-de-beneficiario']}
                        </Form.Control.Feedback> */}
                      
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Clabe o cuenta bancaria<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          name='cuenta-bancaria'
                          value={props.values['cuenta-bancaria'] ? props.values['cuenta-bancaria'] : ''}
                          placeholder="Escribe la cuenta"
                          onChange={props.handleChange}
                          // pattern="[0,9]{1,20}"
                          // title="Ingrese una cuenta bancaria valida. Solo numeros"
                          required
                          // isInvalid={!!props.errors['cuenta-bancaria']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['cuenta-bancaria']}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

// const validationSchema = Yup.object().shape({
//   'nombre-de-beneficiario': Yup.string().required("Campo requerido"),
//   'cuenta-bancaria': Yup.number().required("Campo requerido"),
//   'banco': Yup.string().required("Campo requerido"),
//   'rfc': Yup.string().required("Campo requerido"),
//   'direccion-fiscal': Yup.string().required("Campo requerido"),
// });


const onSubmitForm = async (values, actions, item_id, history, setData) => {
  try {
    const body = {
      item_id,
      fields: values
    }
    console.log(JSON.stringify(body))
    // const response = await Service.saveUser(values)
    // if (response.status === 200) {
    const response = await Service.getProviderData()
    setData(response.data)
    Swal.fire({
      icon: 'success',
      text: 'Proceso correcto',
      showConfirmButton: false,
      timer: 1500
    })
    // }
    history.push(`/app${PROVIDER_DATA_ROUTE}`)
    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}



export default withRouter(ProviderData);
