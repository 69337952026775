import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import * as Yup from "yup";
import { USERS_ROUTE, PROVIDER_REGISTER_ROUTE } from '../../../routes/types'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'

// const validationSchema = Yup.object().shape({
//   'title': Yup.string().required("Campo requerido"),
//   'apellidos': Yup.string().required("Campo requerido"),
//   'email': Yup.string().email('Invalid email').required("Campo requerido"),
//   'estatus': Yup.string().required("Campo requerido"),

// });

const UserRegisterProvider = ({ history, dataProvider}) => {
  dataProvider = JSON.parse(localStorage.getItem('registrarProveedor'))
  

  console.log(dataProvider)
  console.log("veamos")
  return (

    <React.Fragment>
      
      <Container className="form-provider">
        <Row className="justify-content-center">
        

          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${PROVIDER_REGISTER_ROUTE}`)}
          />
        </Col>
        
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">Agregar nuevo usuario</h1>
          </Col>
        </Row>

        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "title": '',
                "apellidos": '',
                "email": '',
                "tipo-de-usuario": 'Proveedor',
                "estatus": '',
                "constrasena": '',
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, actions, history, dataProvider)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">

                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='title'
                          name='title'
                          placeholder="Escribe el nombre"
                          onChange={props.handleChange}
                          value={props.values.title}
                          required
                          // isInvalid={!!props.errors.title}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.title}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Apellidos<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='apellidos'
                          name='apellidos'
                          placeholder="Escribe los apellidos"
                          onChange={props.handleChange}
                          value={props.values['apellidos']}
                          required
                          // isInvalid={!!props.errors.apellidos}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.apellidos}
                        </Form.Control.Feedback> */}
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Email<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="email"
                          id='email'
                          name='email'
                          placeholder="Escribe el email"
                          onChange={props.handleChange}
                          value={props.values['email']}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          title="correo invalido.Un campo de correo debe tener la siguiente estructura: caracteres@caracteres.dominio"
                          required
                          // isInvalid={!!props.errors.email}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.email}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Tipo de usuario<span>*</span></Form.Label>
                        <Form.Control
                          as="select"
                          id='tipo-de-usuario'
                          name='tipo-de-usuario'
                          value={"Proveedor"}
                          required
                          
                        >
                          <option value="Proveedor">Proveedor</option>
                        </Form.Control>
                        
                      </Form.Group>
                    
                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Estatus<span>*</span></Form.Label>
                        <Form.Control
                          as="select"
                          id='estatus'
                          name='estatus'
                          // isInvalid={!!props.errors.estatus}
                          onChange={props.handleChange}
                          value={props.values.estatus}
                          required
                        >
                          <option>Selecciona el estatus</option>
                          <option value="Activo">Activo</option>
                          <option value="Inactivo">Inactivo</option>
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.estatus}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Contraseña<span>*</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="password"
                          id='constrasena'
                          name='constrasena'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values.constrasena}
                          pattern=".{8,}"
                          title="Debe contener 8 o más caracteres"
                          required
                          // isInvalid={!!props.errors.constrasena}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors.constrasena}
                        </Form.Control.Feedback> */}
                      </Form.Group>

                    </Form.Row>

                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, actions, history, dataProvider) => {
    try {
      const usuario = {
        constrasena:values.constrasena,
        "title": values.title,
        "apellidos": values.apellidos,
        "email": values.email,
        "tipo-de-usuario": values["tipo-de-usuario"],
        "estatus": values.estatus,
        "contrasena-ref":values["constrasena"]
      }
      const body = {"proveedor":dataProvider,"usuario":usuario}
      const response = await Service.saveProviderWithUser(body)
      console.log(response)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          text: 'Proceso correcto',
          showConfirmButton: false,
          timer: 1500
        })
      }
      actions.resetForm()
      history.push(`/app${USERS_ROUTE}`)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'No se pudo procesar la solicitud',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(error)
    }
}

const mapDispatchToProps = {
  resetState,
}


const mapStateToProps = state => ({
  dataProvider: state.createUser
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterProvider)