import React, {useState} from 'react'
import { withRouter } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
//import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import {
  GET_INVOICES_ROUTE,
  COMPLEMENTS_ROUTE,
  MY_DATA_ROUTE,
  PROVIDER_DATA_ROUTE
} from '../../../../routes/types'
import './menuprovider.scss'

const MenuProvider = ({ history, match }) => {
  
  const [provider,setProvider] = useState('')
  const [url,setUrl] = useState('')
  let path = window.location;

  path = path.href
  if(provider==="" || provider===null){
    if (path.indexOf("app/mis-datos")>-1){
        setProvider(['colorSeleccion','','',''])

    } else if (path.indexOf("app/datos-proveedor")>-1){
        setProvider(['','colorSeleccion','',''])

    } else if (path.indexOf("app/complementos-pago")>-1){
        setProvider(['','','colorSeleccion',''])

    } else if (path.indexOf("app/obtener-facturas")>-1) {
        setProvider(['','','','colorSeleccion'])
    }
    setTimeout(function(){ refresh(setUrl) }, 2000);
  }


  return (
    <Container fluid={true} className="margin-navbar">
      <Navbar bg="primary" expand="lg" variant="dark" className="menu-sucursal">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          <img src={url}  width="92" height="82"/>
            <Nav.Link className={provider[0]} onClick={() => cambiarColor(setUrl,setProvider,0,history,`${match.path}${MY_DATA_ROUTE}`)}><i className="fas fa-user-circle g_icons"></i> Mi usuario</Nav.Link>
            <Nav.Link className={provider[1]} onClick={() => cambiarColor(setUrl,setProvider,1,history,`${match.path}${PROVIDER_DATA_ROUTE}`)}><i className="fas fa-truck g_icons"></i> Mis datos</Nav.Link>
            <Nav.Link className={provider[2]} onClick={() => cambiarColor(setUrl,setProvider,2,history,`${match.path}${COMPLEMENTS_ROUTE}`)}><i className="fas fa-file-alt g_icons"></i> Mis complementos</Nav.Link>
            <Nav.Link className={provider[3]} onClick={() => cambiarColor(setUrl,setProvider,3,history,`${match.path}${GET_INVOICES_ROUTE}`)}><i className="fas fa-file-invoice g_icons"></i> Mis facturas</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

const cambiarColor = (setUrl,setProvider,number,history,ruta) =>{
  setTimeout(function(){ setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"]) }, 2000);
  if(number===0){
    setProvider(['colorSeleccion','','',''])
  } else if(number===1) {
    setProvider(['','colorSeleccion','',''])
  } else if(number===2) {
    setProvider(['','','colorSeleccion',''])
  } else {
    setProvider(['','','','colorSeleccion'])
  }
  history.push(ruta)
}

const refresh = async (setUrl)=>{

  setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"])
  console.log("se mando a llamar")

}

export default withRouter(MenuProvider)