import {
    SET_COMPLEMENTS_DATA,
    RESET_STATE
} from '../types'

export const setComplementsData = value => (
    {
        type: SET_COMPLEMENTS_DATA,
        payload: value
    }
)

export const resetState = value => (
    {
        type: RESET_STATE
    }
)
