import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import roles from './roles'
import NotFound from '../views/notfound'
import Header from '../common/components/header'
import Footer from '../common/components/footer'
import { HOME_ROUTE } from '../routes/types'

const AuthenticatedRoute = ({ history, match }) => {

  const [routes, setRoutes] = useState([])
  const [role, setRole] = useState([])
  useEffect(() => {
    let token = {}
    token = JSON.parse(localStorage.getItem('token'))
    console.log("el perfil:", token.perfil)
    if (token) {
      setRoutes(roles[token.perfil].routes)
      setRole(token.perfil)
    }
    else {
      history.push(HOME_ROUTE)
    }
  }, [history])

  return (
    <React.Fragment>
      <Header role={role} />
      <Switch>
        {routes.map((route) => {
          return <Route
            exact
            key={route.url}
            component={route.component}
            path={`${match.path}${route.url}`}
          />
        })}
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );

}

export default AuthenticatedRoute;