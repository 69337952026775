import http from '../../../../http';


class Service {

  saveProvider = async () => {
    return await http.post({
      url: `/crear-proveedor`,
    })
  }

  updateProvider = async (body) => {
    return await http.put({
      url: `/editar-proveedor`,
      body
    })
  }
}

export default new Service()
