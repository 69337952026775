import http from '../../../../http';


class Service {

  saveUser = async (body) => {
    return await http.post({
      url: `/crear-usuario`,
      body
    })
  }

  saveProviderWithUser = async (body) => {
    return await http.post({
      url: `/crear-proveedor-usuario`,
      body
    })
  }

  getProvider = async () => {
    return await http.get({
      url: ``,
    })
  }
}

export default new Service()
