import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import moment from 'moment'
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination';
import {EDIT_DEBTS_TO_PAY_ROUTE } from '../../../routes/types'
import { COMPLEMENTS_ROUTE } from '../../../routes/types'
import {setDebtsToPayData} from '../../owner/editdebtsToPay/actions'
import {setFilterComplementsData} from '../complements/actions'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'

const DebtsToPay = ({ history, setDebtsToPayData, setFilterComplementsData, Conciliacion }) => {
  const perfil = JSON.parse(localStorage.getItem('token')).perfil
  const [enabledInput, toggleInput] = useState('')
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState([])
  const [filtro, setFiltro] = useState([])

  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        let response;
        
        if(Object.entries(Conciliacion).length === 0){
          response = await Service.getDebts()
        }else{
          response = await Service.filterDebtsItem({"item_id":Conciliacion["item_id"]})
        }

        if (isSuscribed) {
          setData(response.data.response)
          setPageData(response.data['total-resultado'])
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: error,
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        {
          perfil ==='Administrador' &&
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="titulos">Mis cuentas por pagar</h1>
          </Col>
        }
        {
          perfil ==='Operador' &&
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="titulos">Mis cuentas por pagar</h1>
          </Col>
        }
        {
          perfil ==='Owner' &&
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className="titulos">Cuentas por pagar</h1>
          </Col>
        }
      </Row>


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                <th>Concepto</th>
                <th>Monto</th>
                <th>Nombre proveedor</th>
                <th>Fecha requerida de pago</th>
                <th>Fecha de pago real</th>
                {/* {
                  perfil !== "Owner" &&
                  <th>Requiere complemento</th>
                } */}
                
              
                  <th>Requiere complemento</th>
                
                
                
                { perfil!== "Proveedor" &&
                  <th>Complemento recibido</th>}
                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th width="150px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {renderList(data, enabledInput, toggleInput, history, setDebtsToPayData,setFilterComplementsData,perfil)}
            </tbody>
          </Table>
        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, enabledInput, toggleInput, history, filtro, setDebtsToPayData,setFilterComplementsData,perfil)}
    </Container>

  )
}


const searchBar = (setData, setPageData, setFiltro) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{ buscar: '', column: '' }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData, setPageData, setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option>Selecciona la columna a filtrar</option>
                <option value="fecha">Fecha de pago real</option>
                <option value="fecha-requerida-de-pago">Fecha requerida de pago</option>
                <option value="proveedor">Proveedor</option>
                <option value="estatus">Estatus</option>
                <option value="title">Concepto</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  type="text"
                  name='buscar'
                  value={props.values.buscar}
                  onChange={props.handleChange}
                  placeholder="Buscar" />

                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData, setPageData, setFiltro) => {
  if(values.column=="estatus"){
    values.buscar = values.buscar.toUpperCase()
  }
  try {
    const body = {
      [values.column]: values.buscar
    }
    console.log(JSON.stringify(body))
    setFiltro(body);
    const response = await Service.filterDebt(body)
    if (response.status === 200) {
      setData(response.data.response)
      setPageData(response.data["total-resultado"])
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "error al procesar el filtro",
      showConfirmButton: false,
      timer: 1500
    })
    console.error(error)
  }

}


const renderList = (debts, enabledInput, toggleInput, history, setDebtsToPayData, setFilterComplementsData,perfil) => debts.map((debt, index) => {
  return <tr key={index}>
    <td>{debt['Concepto']}</td>
    {montoDecimal(debt['Monto'])}
    {/* <td>{debt['Monto']}</td> */}
    <td>{debt['Nombre proveedor']}</td>
    <td>{debt['Fecha requerida de pago'] && moment(debt['Fecha requerida de pago'][0].start_date).format('DD/MM/YYYY')}</td>
    <td>{debt['Fecha de Pago Real'] && moment(debt['Fecha de Pago Real'][0].start_date).format('DD/MM/YYYY')}</td>
    <td>{debt['Requiere Complemento']}</td>
    
    
    {/* {
      perfil !=="Owner" &&
      <td className="box_icons_tables">
      <i
        onClick={() => changeComplement(debt['item_id'], 'Si')}
        className={debt['Requiere Complemento'] === 'Si' ? "fas fa-yes i_icons select-icon" : "fas fa-yes i_icons"}>Si</i>
      <i
        onClick={() => changeComplement(debt['item_id'], 'No')}
        className={debt['Requiere Complemento'] === 'No' ? "fas fa-no i_icons select-icon" : "fas fa-no i_icons"}>No</i>
    </td>
    } */}
    
    {
      perfil!=="Proveedor" &&
        <td onClick={() => assignDataComplements(debt,history,setFilterComplementsData)}>{debt['Complemento Recibido']}</td>
      }
    <td className="box_icons_tables">
      <Formik
        enableReinitialize={true}
        initialValues={{
          'estatus': debt['Estatus']
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Form.Control
              as="select"
              id='column'
              name='column'
              className={colorCombo(props.values.estatus)}
              onChange={(e) => updateDebt({ estatus: e.target.value }, debt['item_id'])}
              value={props.values.estatus}
            >
              <option>Selecciona la columna a filtrar</option>
              <option value="Pendiente">Pendiente</option>
              <option value="Cancelado">Cancelado</option>
              <option value="Pagado">Pagado</option>
              <option value="Inactiva">Inactiva</option>
            </Form.Control>
          </Form>
        )}
      </Formik>
      
    </td>
    <td className="box_icons_tables" width="150px">
      {
        ((perfil != "Administrador") && (perfil != "Operador")) &&
        <i
        onClick={() => deleteDebtToPay(debt['item_id'])}
        className="fas fa-trash i_icons">
      </i>
      }
      
      <i
        // onClick={() => history.push(`/app${EDIT_DEBTS_TO_PAY_ROUTE}`)}
        onClick={() => assignData(debt, setDebtsToPayData, history, true)}
        className="fas fa-pen i_icons">
      </i>
      <i
        onClick={() => assignData(debt, setDebtsToPayData, history, false)}
        className="fas fa-eye i_icons">
      </i>
      {
        debt['Estatus']==="Pagado" &&
     
      <i
        onClick={() => sendNotifications(debt['item_id'])}
        className="fas fa-bell i_icons">
      </i>
       }
    </td>
  </tr>
})

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  return <td>
    <Form>
      <Form.Row>
        <Form.Group as={Col} md="2">
          {'$'}
        </Form.Group>
        <Form.Group as={Col} md="10">
          {cadena1.split("").reverse().join('')}
        </Form.Group>
      </Form.Row>
    </Form>
  </td>
}


const assignData = (debt, setDebtsToPayData, history,accion) => {
  const body = {
    "Concepto":debt['Concepto'],
    "Monto":debt['Monto'],
    "Nombre proveedor":debt['Nombre proveedor'],
    "Fecha requerida de pago":debt['Fecha requerida de pago'][0]["start"],
    "Requiere Complemento":debt['Requiere Complemento'],
    "Complemento Recibido":debt['Complemento Recibido'],
    "Estatus":debt['Estatus'],
    "item_id":debt['item_id'],
    "accion":accion,
    "Nombre Usuario Responsable":debt["Nombre Usuario Responsable"]
  }
  setDebtsToPayData(body)
  localStorage.setItem('editarCuentaPorpagar', JSON.stringify(body))
  history.push(`/app${EDIT_DEBTS_TO_PAY_ROUTE}`)
}

const assignDataComplements = (debt,history,setFilterComplementsData) => {
  if(debt["Complemento Recibido"]== "Si"){
    const body = {
      "item_id":debt["item_id"],
    }
    setFilterComplementsData(body)
    history.push(`/app${COMPLEMENTS_ROUTE}`)
  }
}

const colorCombo = (estatus) => {
  if(estatus==="Pagado"){
    return "pagada-debs"
  }else if(estatus==="Pendiente"){
    return "pendiente-debs"
  }else{
    return "cancelada-inactiva-debs"
  }
}
const sendNotifications = async (debt)=>{
  try {
    const body = {
      "accion":1,
      "item_id":debt
    }
    const response = await Service.notiDebt(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    console.error(error)
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}




const deleteDebtToPay = async (item_id) => {
  try {
    const response = await Service.deleteDebt({ item_id })
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const updateDebt = async (values, item_id) => {
  try {
    const body = {
      item_id,
      fields: {
        ...values
      }
    }

    const response = await Service.editDebt(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }

  } catch (error) {
    console.error(error)
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}

const renderPagination = (data, setData, pageData, enabledInput, toggleInput, history, filtro, setDebtsToPayData, setFilterComplementsData, perfil) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,enabledInput,toggleInput,history,filtro,setDebtsToPayData,setFilterComplementsData,perfil)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body, data, setData, enabledInput, toggleInput, history, filtro, setDebtsToPayData, setFilterComplementsData, perfil) => {
  let response;
  try {
    if(filtro!==null && filtro.length>0){
      if(filtro.fecha!==undefined){body={"fecha":filtro.fecha,"pagina":body.pagina}}
      if(filtro.proveedor!==undefined){body={"proveedor":filtro.proveedor,"pagina":body.pagina}}
      if(filtro.estatus!==undefined){body={"estatus":filtro.estatus,"pagina":body.pagina}}
      console.log(body+"2")
      response = await Service.filterDebt(body);
    }else{
      response = await Service.getDebtsPage(body);
    }
    setData(response.data.response);
    renderList(data,enabledInput,toggleInput,history,setDebtsToPayData,setFilterComplementsData,perfil)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const mapDispatchToProps = {
  setDebtsToPayData,
  setFilterComplementsData,
  resetState,
}

const mapStateToProps = state => ({
  Conciliacion: state.filterDebtsToPayData
})

export default connect(mapStateToProps,mapDispatchToProps)(DebtsToPay);
