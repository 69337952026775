import {
    SET_PROVIDER_DATA,
    RESET_STATE
} from '../types'

export const setProviderData = value => (
    {
        type: SET_PROVIDER_DATA,
        payload: value
    }
)

export const resetState = value => (
    {
        type: RESET_STATE
    }
)
