import {
  PROVIDER_ROUTE,
  PROVIDER_REGISTER_ROUTE,
  DEBTS_TO_PAY_ROUTE,
  GET_INVOICES_ROUTE,
  COMPLEMENTS_ROUTE,
  MY_DATA_ROUTE,
  EDIT_INVOICE_ROUTE,
  USERS_ROUTE,
  USER_REGISTER_ROUTE,
  USER_REGISTER_PROVIDER_USER,
  CONFIGURATION_ROUTE,
  CONFIGURATION_REGISTER_ROUTE,
  PROVIDER_DATA_ROUTE,
  UPLOAD_INVOICE_ROUTE,
  UPLOAD_COMPLEMENT_ROUTE,
  EDIT_USER_ROUTE,
  EDIT_COMPLEMENT_ROUTE,
  EDIT_PROVIDER_ROUTE,
  EDIT_DEBTS_TO_PAY_ROUTE,
  EDIT_INVOICES_RECEIVED_ROUTE,
  SAT_INVOICES_RECEIVED_ROUTE,
  SAT_ISSUED_RECEIVED_ROUTE,
  SAT_COMPLEMENTS_RECEIVED_ROUTE,
  SAT_COMPLEMENTS_ISSUED_ROUTE,
  SAT_CONCILIATION_ROUTE,
  SAT_NOTIFICATIONS_ROUTE
  
} from './types'

// import Admin from '../views/admin'

import Provider from '../views/admin/providers'
import ProviderRegister from '../views/admin/providersRegister'
import DebtsToPay from '../views/admin/debtsToPay'
import InvoicesReceibed from '../views/admin/invoicesReceived'
import Complements from '../views/admin/complements'
import MyData from '../views/admin/myData'
import EditInvoice from '../views/admin/editInvoice'
import Users from '../views/owner/users'
import UserRegister from '../views/owner/usersRegister'
import ConfigurationRegister from '../views/owner/configurationRegister'
import Configuration from '../views/owner/configuration'
import ProviderData from '../views/provider/providerData'
import UploadInvoice from '../views/provider/uploadInvoice'
import UploadComplement from '../views/provider/uploadComplement'
import EditUser from '../views/owner/editUser'
import EditProvider from '../views/owner/editProvider'
import EditComplemnt from '../views/owner/editComplement'
import EditdebtsToPay from '../views/owner/editdebtsToPay'
import EditdInvoicesReceived from '../views/owner/editdInvoicesReceived'
import RegisterProviderUser from '../views/owner/RegisterProviderUser'
import SATinvoicesReceived from '../views/owner/SATinvoicesReceived'
import IssuedInvoices from '../views/owner/issuedInvoices'
import ReceivedComplements from '../views/owner/ReceivedComplements'
import IssuedComplemets from '../views/owner/IssuedComplemets'
import Conciliation from '../views/owner/Conciliation'
import Notifications from '../views/owner/Notifications'

export default {
  Administrador: {
    routes: [
      {
        url: DEBTS_TO_PAY_ROUTE,
        component: DebtsToPay
      },
      {
        url: PROVIDER_ROUTE,
        component: Provider
      },
      {
        url: EDIT_PROVIDER_ROUTE,
        component: EditProvider
      },
      {
        url: PROVIDER_REGISTER_ROUTE + '/:id?',
        component: ProviderRegister
      },
      {
        url: USER_REGISTER_PROVIDER_USER,
        component: RegisterProviderUser
      },
      {
        url: GET_INVOICES_ROUTE,
        component: InvoicesReceibed
      },
      {
        url: COMPLEMENTS_ROUTE,
        component: Complements
      },
      {
        url: MY_DATA_ROUTE,
        component: MyData
      },
      {
        url: EDIT_INVOICE_ROUTE,
        component: EditInvoice
      },
      {
        url: EDIT_COMPLEMENT_ROUTE,
        component: EditComplemnt
      },
      {
        url: EDIT_DEBTS_TO_PAY_ROUTE,
        component: EditdebtsToPay
      },
      {
        url: EDIT_INVOICES_RECEIVED_ROUTE ,
        component: EditdInvoicesReceived
      },
    ]
  },
  Owner: {
    routes: [
      {
        url: DEBTS_TO_PAY_ROUTE,
        component: DebtsToPay
      },
      {
        url: USERS_ROUTE,
        component: Users
      },
      {
        url: USER_REGISTER_ROUTE,
        component: UserRegister
      },
      {
        url: CONFIGURATION_ROUTE,
        component: Configuration
      },
      {
        url: CONFIGURATION_REGISTER_ROUTE,
        component: ConfigurationRegister
      },
      {
        url: MY_DATA_ROUTE,
        component: MyData
      },
      {
        url: GET_INVOICES_ROUTE,
        component: InvoicesReceibed
      },
      {
        url: PROVIDER_ROUTE,
        component: Provider
      },
      {
        url: PROVIDER_REGISTER_ROUTE + '/:id?',
        component: ProviderRegister
      },
      {
        url: USER_REGISTER_PROVIDER_USER,
        component: RegisterProviderUser
      },
      {
        url: COMPLEMENTS_ROUTE,
        component: Complements
      },
      {
        url: EDIT_USER_ROUTE,
        component: EditUser
      },
      {
        url: EDIT_PROVIDER_ROUTE,
        component: EditProvider
      },
      {
        url: EDIT_COMPLEMENT_ROUTE,
        component: EditComplemnt
      },
      {
        url: EDIT_DEBTS_TO_PAY_ROUTE,
        component: EditdebtsToPay
      },
      {
        url: EDIT_INVOICES_RECEIVED_ROUTE ,
        component: EditdInvoicesReceived
      },
      {
        url: SAT_INVOICES_RECEIVED_ROUTE ,
        component: SATinvoicesReceived
      },
      {
        url: SAT_ISSUED_RECEIVED_ROUTE ,
        component: IssuedInvoices
      },
      {
        url: SAT_COMPLEMENTS_RECEIVED_ROUTE,
        component: ReceivedComplements
      },
      {
        url: SAT_COMPLEMENTS_ISSUED_ROUTE,
        component: IssuedComplemets
      },
      {
        url: SAT_CONCILIATION_ROUTE,
        component: Conciliation
      },
      {
        url: SAT_NOTIFICATIONS_ROUTE,
        component: Notifications
      }
    ]
  },

  Proveedor: {
    routes: [
      {
        url: GET_INVOICES_ROUTE,
        component: InvoicesReceibed
      },
      {
        url: MY_DATA_ROUTE,
        component: MyData
      },
      {
        url: PROVIDER_DATA_ROUTE,
        component: ProviderData
      },
      {
        url: UPLOAD_INVOICE_ROUTE,
        component: UploadInvoice
      },
      {
        url: COMPLEMENTS_ROUTE,
        component: Complements
      },
      {
        url: UPLOAD_COMPLEMENT_ROUTE,
        component: UploadComplement
      },
      {
        url: EDIT_COMPLEMENT_ROUTE,
        component: EditComplemnt
      },
      {
        url: EDIT_INVOICES_RECEIVED_ROUTE ,
        component: EditdInvoicesReceived
      },
    ]
  },

  Operador: {
    routes: [
      {
        url: DEBTS_TO_PAY_ROUTE,
        component: DebtsToPay
      },
      {
        url: MY_DATA_ROUTE,
        component: MyData
      },
      {
        url: GET_INVOICES_ROUTE,
        component: InvoicesReceibed
      },
      {
        url: PROVIDER_ROUTE,
        component: Provider
      },
      {
        url: COMPLEMENTS_ROUTE,
        component: Complements
      },
      {
        url: EDIT_COMPLEMENT_ROUTE,
        component: EditComplemnt
      },
      {
        url: EDIT_DEBTS_TO_PAY_ROUTE,
        component: EditdebtsToPay
      },
      {
        url: EDIT_INVOICES_RECEIVED_ROUTE ,
        component: EditdInvoicesReceived
      },
    ]
  }

}