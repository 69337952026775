import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Pagination from 'react-bootstrap/Pagination';
import {EDIT_INVOICES_RECEIVED_ROUTE,COMPLEMENTS_ROUTE} from '../../../routes/types'
import {setInvoicesData} from "../../provider/uploadInvoice/actions"
import {setEditInvoices} from "../../owner/editdInvoicesReceived/actions"
import {setFilterComplementsData} from '../complements/actions'
import { connect } from 'react-redux'



const InvoicesReceived = ({ history,setInvoicesData,setEditInvoices,setFilterComplementsData}) => {
  const perfil = JSON.parse(localStorage.getItem('token')).perfil
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState([])
  const [filtro, setFiltro] = useState([])
  const [invoicesFields, setInvociesFields] = useState([])


  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        const response = await Service.getInvoices()
        if (isSuscribed) {
          setData(response.data.response)
          setPageData(response.data['total-resultado'])
          setInvociesFields(response.data.usuario)
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: "Hubo un error al cargar",
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error("ver esta log"+error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])

  return (

    <Container>
      <Row className="justify-content-center">
        {
          perfil === 'Administrador' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis facturas recibidas</h1>
          </Col>
        }
        {
          perfil === 'Operador' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis facturas recibidas</h1>
          </Col>
        }
        {
          perfil === 'Owner' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Facturas recibidas</h1>
          </Col>
        }
        {
          perfil === 'Proveedor' &&
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Mis facturas</h1>
          </Col>
        }
        {/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">{perfil === 'Proveedor' ? 'Facturas' : 'Facturas recibidas'}</h1>
        </Col> */}
        {
        perfil === 'Proveedor' &&
        
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <ButtonToolbar className="justify-content-center margin-withtable  mb-1">
              <Button variant="primary" type="submit" onClick={() => assignData(history,invoicesFields,data,setInvoicesData)}>
                subir factura
                  </Button>
            </ButtonToolbar>
          </Col>
       
      }
      </Row>

      


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro, perfil)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
              
                <th width="600px">UUID</th>
                <th width="250px">Descripción o concepto</th>
                {
                  perfil != "Proveedor" &&
                  <th>RFC Proveedor</th>

                }
                
                <th>Folio factura</th>
                <th width="150px">Subtotal sin IVA</th>
                <th width="150px">Total</th>
                <th>Método pago</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th width="500px">Fecha de pago</th>
                    
                {
                   ((perfil !="Owner") && (perfil != "Proveedor"))  &&
                  <th>Requiere Complemento</th>
                  
                }
                
                {
                  perfil === "Proveedor" &&
                  <th>Complemento</th>
                }
                {
                  perfil != "Proveedor" &&
                  <th className="acciones-col" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acciones&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                }
                {
                  perfil != "Proveedor" &&
                  <th width="150px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eliminar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                }
                
                <th>Archivos</th>
              </tr>
            </thead>
            <tbody>
              {renderList(data, history, setEditInvoices, setFilterComplementsData,perfil)}
            </tbody>
          </Table>

          {/* <PaginationController
            paginacion={data.dsal.paginacion && data.dsal.paginacion[0]}
            setData={setData}
            getPage={Service.getListApplied}
            num_results={results}
            body={body}
            setResults={setResults}
          /> */}

        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, history, filtro, setEditInvoices,setFilterComplementsData,perfil)}
    </Container>

  )
}


const searchBar = (setData, setPageData, setFiltro, perfil) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{
      column: '',
      buscar: ''
    }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData, setPageData, setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option>Selecciona la columna a filtrar</option>
                <option value="estatus">Estatus</option>
                {
                  perfil !=="Proveedor" &&
                  <option value="rfc"> RFC Proveedor</option>
                }
                
                <option value="metodo-de-pago">Método de pago</option>
                <option value="uuid">UUID</option>
                <option value="folio-de-factura">Folio factura</option>
                <option value="descripcion-o-concepto">Descripcion o concepto</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>
                <Form.Control
                  type="text"
                  name='buscar'
                  value={props.values.buscar}
                  onChange={props.handleChange}
                  placeholder="Buscar" />
                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData,setPageData,setFiltro) => {
  if(values.column==="estatus" || values.column=="rfc" || values.column=="metodo-de-pago"){
    values.buscar = values.buscar.toUpperCase()
  }
  console.log(values)
  try {
    const body = {
      [values.column]: values.buscar
    }
    // console.log(JSON.stringify(body))
    const response = await Service.filterInvoice(body);
    setFiltro(body);
    if (response.status === 200) {
      setData(response.data.response);
      setPageData(response.data["total-resultado"])
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: "success",
      title: error.response.data.dsal.errno[0].mensaje,
      showConfirmButton: false,
      timer: 1500
    })
    console.error(error)
  }

}


/*
<td>
      <Formik
        enableReinitialize={true}
        initialValues={{
          'descripcion-o-concepto': invoice['Descripción o Concepto']
        }}
        onSubmit={(values, actions) => updateInvoice(values, actions, invoice['item_id'])}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Form.Control
              // disabled={index === enabledInput ? false : true}
              name='descripcion-o-concepto'
              className="descripcion-inv"
              value={props.values['descripcion-o-concepto']}
              type="text"
              onChange={props.handleChange}
        //     // onBlur={() => toggleInput('')}
        //     // onBlur={() => sendElemet(props.handleSubmit, toggleInput)}
        //     />
        //   </Form>
        // )}
      </Formik>
      {/* {invoice['Descripción o Concepto']} *//*}
      </td>
*/
const renderList = (setInvoices, history, setEditInvoices, setFilterComplementsData,perfil) => setInvoices.map((invoice, index) => {
  return <tr key={index}>
    <td>{invoice['UUID']}</td>
    <td>{invoice['Descripción o Concepto']}</td>
    {
      perfil != "Proveedor" &&
      <td>{invoice["RFC Proveedor"]}</td>
    }
    
    <td>{invoice['Folio de Factura']}</td>
    {montoDecimal(invoice['Subtotal SIN IVA'])}
    {montoDecimal(invoice['Total'])}
    <td>{invoice['Método de Pago']}</td>
    <td>{invoice['Estatus']}</td>
    <td width="300px">{ 
        invoice["Fecha pago real"] !== "Invalid date" ?
            invoice["Fecha pago real"]
          :
           ""
        }</td>
    
    {/* {
      perfil ==="Proveedor" &&
      <td>{invoice['Fecha pago real']}</td>
    } */}
    {/* {  
      perfil !=="Proveedor" &&
      <td width="300px">{ 
        invoice["Fecha pago"] !== undefined ?
            invoice["Fecha pago"][0]["start_date"]
          :
           ""
        }</td>

    } */}
    
    { 
      ((perfil != "Proveedor") && (perfil != "Owner")) &&
      <td>{
        invoice["Requiere Complemento"] !== undefined ?
            invoice["Requiere Complemento"]
          :
            invoice["Requiere Complemento"]}</td>
    }
    
    {
      perfil === "Proveedor" && 
      <td onClick={()=> assignDataComplements(invoice,history,setFilterComplementsData)}>
      {
        invoice["complemento"] !== undefined ?
            invoice["complemento"]
          :
            ""
      }</td>
    }
    {
      perfil != "Proveedor" &&
      <td className="acciones-cell">
      <Formik
        enableReinitialize={true}
        initialValues={{
          'Acciones': ''
        }}
      >
        <Form>
          <Form.Control
            as="select"
            id='tipo-de-usuario'
            name='tipo-de-usuario'
            onChange={(e) => sendEstatus(invoice['item_id'], e.target.value)}
          >
            <option>Acciones</option>
            <option value="1">Aprobar</option>
            <option value="2">Rechazar por Temas Fiscales</option>
            <option value="3">Rechazar por Complementos</option>
            <option value="4">Rechazar por Entregables</option>
          </Form.Control>
        </Form>
      </Formik>

      {/* {invoice['Descripción o Concepto']} */}
    </td>
    }
    {
      perfil != "Proveedor" &&
      <td className="box_icons_tables" width="150px">
        {
          ((perfil !="Administrador") && (perfil != "Operador"))  &&
          <i
          onClick={() => deleteInvoice(invoice['item_id'])}
          className="fas fa-trash i_icons">
          </i>
        }
      
      <i
        onClick={() => assignData1(invoice,setEditInvoices,history,false)}
        className="fas fa-eye i_icons">
      </i>
      {
        perfil!=="Owner" &&
        <i
        onClick={() => assignData1(invoice,setEditInvoices,history,true)}
        className="fas fa-pen i_icons">
      </i>
      }
      {/* <i
        onClick={() => assignData1(invoice,setEditInvoices,history,true)}
        className="fas fa-pen i_icons">
      </i> */}
    </td>

    }
    
    <td className="box_icons_tables">
      <a className="url-send" href={invoice["url-pdf"]} target="_blank">
        <i className="fas fa-file-pdf i_icons">
        </i>
      </a>
      <a className="url-send" href={invoice["url-xml"]} target="_blank">
        <i
          className="fas fa-file i_icons">
            
        </i>
      </a>
    </td>

  </tr>
})

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  return <td>
    <Form>
      <Form.Row>
        <Form.Group as={Col} md="2">
          {'$'}
        </Form.Group>
        <Form.Group as={Col} md="10">
          {cadena1.split("").reverse().join('')}
        </Form.Group>
      </Form.Row>
    </Form>
  </td>
}

const assignData1 = (invoice, setEditInvoices, history,accion) => {
  setEditInvoices({"data":invoice,"accion":accion})
  localStorage.setItem('editarFactura', JSON.stringify({"data":invoice,"accion":accion}))
  history.push(`/app${EDIT_INVOICES_RECEIVED_ROUTE}`)
}

const assignDataComplements = (invoice, history, setFilterComplementsData) => {
  if(invoice["complemento"] !== undefined){
    const body = {
      "item_id" : invoice["Cuenta Por Pagar"]
    }
    setFilterComplementsData(body)
    history.push(`/app${COMPLEMENTS_ROUTE}`)
}
}

// const sendElemet = (handleSubmit, toggleInput) => {
//   handleSubmit()
//   toggleInput('')
// }

const sendEstatus = async (item, option) => {
  try {
    const body = {
      item_id: item,
      option
    }
    const response = await Service.setStatus(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    // history.push(`/app${PROVIDER_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}

const deleteInvoice = async (item_id) => {
  try {
    const body = {
      item_id
    }
    const response = await Service.deleteInvoice(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    // history.push(`/app${PROVIDER_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}

// const updateInvoice = async (values, actions, item_id) => {
//   try {
//     const body = {
//       item_id,
//       fields: {
//         ...values
//       }
//     }

//     const response = await Service.editInvoice(body)
//     if (response.status === 200) {
//       Swal.fire({
//         icon: 'success',
//         text: 'Proceso correcto',
//         showConfirmButton: false,
//         timer: 1500
//       })
//     }

//   } catch (error) {
//     console.error(error)
//     Swal.fire({
//       icon: 'error',
//       text: 'No se pudo procesar la solicitud',
//       showConfirmButton: false,
//       timer: 1500
//     })
//   }
// }


const renderPagination = (data, setData, pageData, history, filtro, setEditInvoices, setFilterComplementsData,perfil) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,history,filtro,setEditInvoices,setFilterComplementsData,perfil)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body,data,setData,history,filtro,setEditInvoices,setFilterComplementsData,perfil)=> {
  let response;
  try {
    if(filtro.length>0){

      if(filtro.estatus!==undefined){body={"estatus":filtro.estatus,"pagina":body.pagina}}
      if(filtro.title!==undefined){body={"title":filtro.title,"pagina":body.pagina}}
      if(filtro["metodo-de-pago"]!==undefined){body={"metodo-de-pago":filtro["metodo-de-pago"],"pagina":body.pagina}}
      response = await Service.filterInvoice(body);

    }else{
      response = await Service.getInvoicesPage(body);
    }
    setData(response.data.response);
    renderList(data,history,setEditInvoices,setFilterComplementsData,perfil)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const mapDispatchToProps = {
  setInvoicesData,
  setEditInvoices,
  setFilterComplementsData,
}

const assignData = (history,invoicesFields,data,setInvoicesData) => {
  setInvoicesData(invoicesFields)
  history.push('/app/subir-factura')
}

export default connect(null,mapDispatchToProps)(InvoicesReceived);
