import http from '../../../../http';


class Service {

  getMyData = async () => {
    return await http.get({
      url: `/perfil-de-usuario`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  uploadFiles = async (body) => {
    return await http.post({
      url: `/upload-key-cer`,
      body
    })
  }

  updateData = async (body) => {
    return await http.put({
      url: `/edit-user-profile`,
      body
    })
  }

  getConfiguration = async () => {
    return await http.get({
      url: `/configuracion-de-cuenta`,
    })
  }

  updateMyData_Conf = async (body) => {
    return await http.put({
      url: `/editar-configuracion`,
      body
    })
  }

  
  
}

export default new Service()
