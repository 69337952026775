import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import * as Yup from "yup";
import { GET_INVOICES_ROUTE } from '../../../routes/types'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'
import Service from './services'

// const validationSchema = Yup.object().shape({
//   'descripcion-o-concepto': Yup.string().required("Campo requerido"),
//   'rfc': Yup.string().required("Campo requerido"),
//   'uuid': Yup.string().required("Campo requerido"),
//   'folio-de-factura': Yup.string().required("Campo requerido"),
//   'subtotal-sin-iva': Yup.number().required("Campo requerido"),
//   'total': Yup.number().required("Campo requerido"),
//   'metodo-de-pago': Yup.string().required("Campo requerido"),
//   'estatus': Yup.string().required("Campo requerido") 

// });

const EditdInvoicesReceived = ({ history, DebtsToPayData }) => {
  DebtsToPayData = JSON.parse(localStorage.getItem('editarFactura'))
  const edit = DebtsToPayData["accion"];
  DebtsToPayData = DebtsToPayData["data"];
  return (                                                                                                                         

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${GET_INVOICES_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">{ edit === true ? "Editar" : "Consultar"} facturas recibidas</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
               "id":DebtsToPayData["ID"],
               "descripcion-o-concepto":DebtsToPayData["Descripción o Concepto"],
               "rfc":DebtsToPayData["RFC Proveedor"],
               "uuid":DebtsToPayData["UUID"],
               "folio-de-factura":DebtsToPayData["Folio de Factura"],
               "subtotal-sin-iva":DebtsToPayData["Subtotal SIN IVA"],
               "total":DebtsToPayData["Total"],
               "metodo-de-pago":DebtsToPayData["Método de Pago"],
               "estatus":DebtsToPayData["Estatus"],
               "item_id":DebtsToPayData["item_id"],
               "Nombre del proveedor":DebtsToPayData["Nombre del proveedor"],
               "Email de Contacto":DebtsToPayData["Email de Contacto"][0]["value"]
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values,history)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Descripción o concepto<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          id='descripcion-o-concepto'
                          name='descripcion-o-concepto'
                          placeholder="Escribe la descripción o concepto"
                          onChange={props.handleChange}
                          value={props.values['descripcion-o-concepto']}
                          isInvalid={!!props.errors['descripcion-o-concepto']}
                          required
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['descripcion-o-concepto']}
                        </Form.Control.Feedback> */}
                       
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">RFC proveedor<span>*</span></Form.Label>
                      <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='rfc'
                          name='rfc'
                          placeholder="Escribe el RFC del proveedor"
                          onChange={props.handleChange}
                          value={props.values['rfc']}
                          required
                          // isInvalid={!!props.errors['rfc']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['rfc']}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">UUID<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='uuid'
                          name='uuid'
                          placeholder="Escribe el UUID"
                          onChange={props.handleChange}
                          value={props.values['uuid']}
                          required
                          // isInvalid={!!props.errors['uuid']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['uuid']}
                        </Form.Control.Feedback> */}
                      
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Folio factura<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='folio-de-factura'
                          name='folio-de-factura'
                          placeholder="Escribe el folio de la factura"
                          onChange={props.handleChange}
                          value={props.values["folio-de-factura"]}
                          required
                          // isInvalid={!!props.errors['folio-de-factura']}
                        >
                        </Form.Control> 
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['folio-de-factura']}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Subtotal sin IVA<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!edit}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='subtotal-sin-iva'
                          name='subtotal-sin-iva'
                          placeholder="Escribe el subtotal sin IVA"
                          onChange={props.handleChange}
                          value={montoDecimal(props.values['subtotal-sin-iva'])}
                          required
                          // isInvalid={!!props.errors['subtotal-sin-iva']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['subtotal-sin-iva']}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Total<span>*</span></Form.Label>
                        <Form.Control
                            readOnly={!edit}
                            className="provider-inputs"
                            type="text"
                            readOnly="true"
                            id='total'
                            name='total'
                            placeholder="Escribe el total"
                            onChange={props.handleChange}
                            value={montoDecimal(props.values['total'])}
                            required
                            // isInvalid={!!props.errors['total']}
                          >
                          </Form.Control>
                          {/* <Form.Control.Feedback type="invalid">
                            {props.errors['total']}
                          </Form.Control.Feedback> */}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Método de pago<span>*</span></Form.Label>
                        <Form.Control
                        readOnly={!edit}
                            className="provider-inputs"
                            as="select"
                            id='metodo-de-pago'
                            name='metodo-de-pago'
                            // placeholder="Escribe el monto"
                            onChange={props.handleChange}
                            value={props.values['metodo-de-pago']}
                            required
                            // isInvalid={!!props.errors['metodo-de-pago']}
                          >
                            <option>Selecciona un Método de Pago</option>
                            <option value="PDD">PDD</option>
                            <option value="PUE">PUE</option>
                          </Form.Control>
                          {/* <Form.Control.Feedback type="invalid">
                            {props.errors['metodo-de-pago']}
                          </Form.Control.Feedback> */}
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Estatus<span>*</span></Form.Label>
                        <Form.Control
                            as="select"
                            id='estatus'
                            name='estatus'
                            // placeholder="Escribe el monto"
                            onChange={props.handleChange}
                            value={props.values['estatus']}
                            required
                            // isInvalid={!!props.errors['estatus']}
                          >
                            <option>Selecciona un Estatus</option>
                            <option value="Recibida">Recibida</option>
                            <option value="Aprobada">Aprobada</option>
                            <option value="Rechazada por Temas Fiscales">Rechazada por Temas Fiscales</option>
                            <option value="Rechazada por Complementos">Rechazada por Complementos</option>
                            <option value="Rechazada por Entregables">Rechazada por Entregables</option>
                          </Form.Control>
                          {/* <Form.Control.Feedback type="invalid">
                            {props.errors['estatus']}
                          </Form.Control.Feedback>
                           */}
                      
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Email de contacto</Form.Label>
                      <Form.Control
                            readOnly="true"
                            className="provider-inputs"
                            type="text"
                            id='Email de Contacto'
                            name='Email de Contacto'
                            placeholder="Escribe el Email de Contacto"
                            onChange={props.handleChange}
                            value={props.values['Email de Contacto']}
                          >
                          </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Nombre proveedor</Form.Label>
                      <Form.Control
                            readOnly="true"
                            className="provider-inputs"
                            type="text"
                            id='Nombre del proveedor'
                            name='Nombre del proveedor'
                            placeholder="Escribe el Nombre del proveedor"
                            onChange={props.handleChange}
                            value={props.values['Nombre del proveedor']}
                          >
                          </Form.Control>
                      
                      </Form.Group>
                    </Form.Row>
                    
                          
                  </div>
                    {
                      edit === true &&
                      <Button className='button primary send-button' type='submit'>
                        <span>Guardar</span>
                      </Button>
                    }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  cadena1= '$' + cadena1.split("").reverse().join('')
  return cadena1
          
       
}

const onSubmitForm = async (values, history) => {
  try {
    const response = await Service.editInvoice(values);
    console.log(response)
    Swal.fire({
      icon: 'success',
      title: 'Proceso correcto',
      showConfirmButton: false,
      timer: 1500
    })
    history.push(`/app${GET_INVOICES_ROUTE}`)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
  }
}

const mapStateToProps = state => ({
  DebtsToPayData: state.editdInvoices
})

const mapDispatchToProps = {
  resetState,
}



export default connect(mapStateToProps, mapDispatchToProps)(EditdInvoicesReceived);
