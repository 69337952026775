import React from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import { COMPLEMENTS_ROUTE } from '../../../routes/types'
import Service from "./services"


const UploadComplement = ({ history}) => {
  const dataUser = JSON.parse(localStorage.getItem('token'))
  return (

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${COMPLEMENTS_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">Subir complemento</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "user":dataUser["item_id"],
                "configuracion":dataUser["configuracion"],
                "complementopdf": '',
                "complementoxml": ''
              }}
              onSubmit={(values, actions) => onSubmitForm(values, history, actions)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Cargue su archivo xml<span> *</span></Form.Label>
                        <Form.File
                          className=".inputs-info"
                          type="file"
                          required
                          id='complementoxml'
                          name='complementoxml'
                          accept=".xml"
                          onChange={(event) => {
                            props.setFieldValue("complementoxml", event.currentTarget.files[0]);
                          }}
                        >
                        </Form.File>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Cargue su archivo pdf</Form.Label>
                        <Form.File
                          className=".inputs-info"
                          type="file"
                          id='complementopdf'
                          name='complementopdf'
                          accept=".pdf"
                          onChange={(event) => {
                            props.setFieldValue("complementopdf", event.currentTarget.files[0]);
                          }}
                        >
                        </Form.File>
                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, history, actions) => {
  try {
    const body = new FormData()
    if(values.complementopdf!=null && values.complementopdf!=""){
      body.append('complementopdf', values.complementopdf)
    }
    body.append('complementoxml', values.complementoxml)
    body.append('user',values['user'])
    body.append('configuracion',values['configuracion'])
    
    const response = await Service.createComplements(body);
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    actions.resetForm()
    history.push(`/app${COMPLEMENTS_ROUTE}`)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'No se pudo procesar la solicitud',
        showConfirmButton: false,
        timer: 1500
      })
    }
}



export default withRouter(UploadComplement);
