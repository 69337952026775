import http from '../../../../http';


class Service {

  getComplementos1 = async () => {
    return await http.get({
      url: `/obtener-complementos-sat-emitidos`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getComplementos1Page = async (body) => {
    return await http.post({
      url: `/obtener-complementos-sat-emitidos`,
      body
    })
  }


  filterComplementos1 = async (body) => {
    return await http.post({
      url: `/obtener-complementos-sat-filtro-emitidos`,
      body
    })
  }
}

export default new Service()
