import http from '../../../../http';


class Service {

  getUsers = async () => {
    return await http.get({
      url: `/usuarios`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getUsersPage = async (body) => {
    return await http.post({
      url: `/usuarios`,
      body,
    })
  }

  deleteProv = async (body) => {
    return await http.delete({
      url: `/eliminar-usuario`,
      body
    })
  }

  filterProvider = async (body) => {
    return await http.post({
      url: `/filtro-proveedores`,
      body
    })
  }
}

export default new Service()
