import http from '../../../../http';


class Service {

  saveProvider = async () => {
    return await http.post({
      url: `/crear-proveedor`,
    })
  }
}

export default new Service()
