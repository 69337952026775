import React from "react"
import MenuGeneral from "./menuGeneral"
import MenuOwner from './menuOwner'
import MenuProvider from './menuProvider'
import MenuOperador from './menuOperator'

const Header = ({ role }) => {
  if (window.location.pathname === '/')
    return null
  else {
    switch (role) {
      case 'Administrador':
        return <MenuGeneral />
      case 'Owner':
        return <MenuOwner />
      case 'Proveedor':
        return <MenuProvider />
      case 'Operador':
        return <MenuOperador />
      default:
        return null;
    }
  }
};

export default Header;

