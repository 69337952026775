import React from "react";
import { HOME_ROUTE } from '../../routes/types'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <React.Fragment>
            <h1>Página no encontrada, Inicia sesión</h1>
            <Link to={HOME_ROUTE}>Iniciar sesión</Link>
        </React.Fragment>
    )
};

export default NotFound;
