import React from "react";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import './footer.scss'

const Footer = ({ history }) => {
    return (
        <footer className='wrapper_footer_logout cerrar-sesion'>
            <OverlayTrigger
                key={'left'}
                placement={'left'}
                overlay={
                    <Tooltip>
                        Cerrar Sesión
                </Tooltip>
                }>
                <span onClick={() => Logout(history)}>
                    
                    <i className="fas fa-sign-out-alt"><span> Salir</span></i>
                </span>
            </OverlayTrigger>
        </footer>
    )
};

const Logout = (history) => {
    Swal.fire({
        title: '¿Esta seguro de cerrar sesión?',
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#e35205',
        cancelButtonColor: '$gris-mediotexto',
        confirmButtonText: '<i class="fa fa-check"></i>',
        cancelButtonText: '<i class="fa fa-times"></i>',
        customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
            cancelButton: 'cancelswal',
        }
    }).then(result => {
        if (result.value) {
            localStorage.clear()
            history.replace("/")
        }
    })

}

export default withRouter(Footer);
