import React from "react"
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// import '../../../views/sucursal/inventoryClosure/inventoryClosure.scss'
import Button from 'react-bootstrap/Button'
import Service from '../../../views/login/services'
// import Service from '../../../views/sucursal/inventoryClosure/services'

const RecuperarModal = ({ show, onHide }) => {

  return <Formik
    enableReinitialize={true}
    initialValues={{
      email: ''
    }}
    onSubmit={(values, actions) => sendForm(values, actions, onHide)}
  >

    {props => (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modalsize"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        className="modalgrupouno"
      >
        <Modal.Header closeButton>
          <Col>
            <Modal.Title id="contained-modal-title-vcenter" className="titlemodal">
              <h3>Ingresa tu email</h3>
            </Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center" as={Row}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-center">
            <Form onSubmit={props.handleSubmit} className="modalform bodyselec">
              <Form.Group className="input_message">
                <InputGroup>
                  <Form.Control
                    name='email'
                    value={props.values.email}
                    type="text"
                    onChange={props.handleChange}
                    placeholder="Escribe tu email"
                  // className="input_message"
                  />
                </InputGroup>
              </Form.Group>
              <Modal.Footer className="justify-content-center">
                <Button type="submit">Recuperar</Button>
              </Modal.Footer>
            </Form>
          </Col>
        </Modal.Body>
      </Modal>)
    }
  </Formik>
}

const sendForm = async (values, actions, onHide) => {
  try {
    console.log(values)
    const response = await Service.RecoverPass(values)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: "Se ha enviado la información a tu email",
        showConfirmButton: true,
        timer: 1500
      })
      // const response = await Service.getInstrucciones()
      // setData(response.data)

      // actions.resetForm()
      // onHide()
    }
    actions.resetForm()
    onHide()
  } catch (error) {
    Swal.fire({
      icon: 'error', 
      text: 'El email ingresado no existe',
      // text: error.response.data.dsal.errno[0].mensaje,
      showConfirmButton: false,
      timer: 1500
    })
  }

  // catch (error) {
  //   Swal.fire({
  //     icon: 'error',
  //     text: 'No se pudo procesar la solicitud',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  //   console.error(error)
  // }

}
export default RecuperarModal