import http from '../../../../http';


class Service {

  getProviderData = async () => {
    return await http.get({
      url: `/perfil-de-proveedor`,
    })
  }

  getProvider = async () => {
    return await http.get({
      url: ``,
    })
  }
}

export default new Service()
