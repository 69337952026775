import http from '../../../../http';


class Service {

  updateDebtsToPay = async (body) => {
    return await http.put({
      url: `/actualizar-cuentas-por-pagar`,
      body
    })
  }

  getInvoice = async (body) => {
    return await http.post({
      url: `/encontrar-factura-cuentas-por-pagar`,
      body
    })
  }
}

export default new Service()
