import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import { PROVIDER_ROUTE } from '../../../routes/types'
import UploadLogo from '../../../common/components/uploadLogo'
// import './provider.scss';


const EditInvoice = ({ history }) => {
  const [imageStore, setImageData] = useState('')

  return (
    // <div className="provider-wrapper">

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/`)}
          />
        </Col>
        <Row className="justify-content-center">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">Agregar proveedor</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                proveedor: '',
                rfc: '',
                "id-proveedor-sap": '',
                "direccion-fiscal": '',
                "razon-social": '',
                "pagina-web": '',
                "email-de-contacto": '',
                "politica-de-pago2": '',
                banco: '',
                "nombre-de-beneficiario": '',
                "cuenta-bancaria": '',
              }}
              onSubmit={(values, actions) => onSubmitForm(values, actions, imageStore, history)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">

                        <UploadLogo
                          imageStore={imageStore}
                          setImageData={setImageData}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre de proveedor</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='proveedor'
                          name='proveedor'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values.proveedor}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Id proveedor interno</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='id-proveedor-sap'
                          name='id-proveedor-sap'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['id-proveedor-sap']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Razón social</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='razon-social'
                          name='razon-social'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['razon-social']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Página web</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='pagina-web'
                          name='pagina-web'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['pagina-web']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Email de contacto</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='email-de-contacto'
                          name='email-de-contacto'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['email-de-contacto']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">RFC</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='rfc'
                          name='rfc'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values.rfc}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Dirección fiscal</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='direccion-fiscal'
                          name='direccion-fiscal'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['direccion-fiscal']}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Política de pagos</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='politica-de-pago2'
                          name='politica-de-pago2'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['politica-de-pago2']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>


                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Banco</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='banco'
                          name='banco'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values.banco}
                        >
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Nombre de beneficiario</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='nombre-de-beneficiario'
                          name='nombre-de-beneficiario'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['nombre-de-beneficiario']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>

                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Clabe o cuenta bancaria</Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='cuenta-bancaria'
                          name='cuenta-bancaria'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          value={props.values['cuenta-bancaria']}
                        >
                        </Form.Control>

                      </Form.Group>

                      <Form.Group as={Col} md="4">

                      </Form.Group>
                    </Form.Row>
                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>






      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, actions, imageStore, history) => {
  try {
    values.logo_data = imageStore
    const response = await Service.saveProvider(values)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${PROVIDER_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}



export default withRouter(EditInvoice);
