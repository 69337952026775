import http from '../../../../http';


class Service {

  getNotificacion = async () => {
    return await http.get({
      url: `/obtener-notificcion`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getNotificacionesPage = async (body) => {
    return await http.post({
      url: `/obtener-notificcion`,
      body
    })
  }

 

  

  filterNotificaciones = async (body) => {
    return await http.post({
      url: `/obtener-notificcion-filtro`,
      body
    })
  }
}

export default new Service()
