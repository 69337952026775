import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import * as Yup from "yup";
import { DEBTS_TO_PAY_ROUTE } from '../../../routes/types'
import { connect } from 'react-redux'
import {
  resetState,
} from './actions'
import { PropagateLoader } from "react-spinners";


// const validationSchema = Yup.object().shape({
//   'title': Yup.string().required("Campo requerido"),
//   'monto': Yup.number().required("Campo requerido"),
//   'fecha-requerida-de-pago': Yup.date().required("Campo requerido"),
//   'estatus': Yup.string().required("Campo requerido"),
  

// });

const EditdebtsToPay = ({ history, match, DebtsToPayData }) => {
  const [data,setData] = useState([])
  const [consulta,setConsulta] = useState(false)
  DebtsToPayData=JSON.parse(localStorage.getItem('editarCuentaPorpagar'))
  if(consulta===false){
    get_facturas(DebtsToPayData,setData)
    setConsulta(true)
  }
  return (                                                                                                                         

    <React.Fragment>
      <Row>
       
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${DEBTS_TO_PAY_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">{DebtsToPayData["accion"]===true ? "Editar" : "Consulta" } cuentas por pagar</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "title": DebtsToPayData['Concepto'],
                "monto": DebtsToPayData['Monto'],
                "nombre-proveedor": DebtsToPayData['Nombre proveedor'],
                "fecha-requerida-de-pago": DebtsToPayData['Fecha requerida de pago'].slice(0,10),
                "requiere-complemento": DebtsToPayData['Complemento Recibido'],
                "requiere-complemento-2": DebtsToPayData['Requiere Complemento'],
                "estatus": DebtsToPayData['Estatus'],
                // "Nombre Usuario Responsable": DebtsToPayData['Nombre Usuario Responsable'],
                
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, actions) => onSubmitForm(values, actions, history, DebtsToPayData['item_id'])}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="8">

                        {/* <UploadLogo
                          imageStore={DebtsToPayData['Logo']}
                          setImageData={setImageData}
                        /> */}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Concepto<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!DebtsToPayData["accion"]}
                          className="provider-inputs"
                          type="text"
                          id='title'
                          name='title'
                          placeholder="Escribe el Concepto"
                          onChange={props.handleChange}
                          value={props.values['title']}
                          required
                          // isInvalid={!!props.errors['title']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['title']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Monto<span>*</span></Form.Label>
                        <Form.Control
                          readOnly={!DebtsToPayData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='monto'
                          name='monto'
                          placeholder="Escribe el monto"
                          onChange={props.handleChange}
                          value={montoDecimal(props.values['monto'])}
                          
                          /* let monto = props.values['monto'] */

                          required
                          // isInvalid={!!props.errors['monto']}
                        >
                        </Form.Control>

                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['monto']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                      <Form.Label className="label-provider">Nombre de proveedor</Form.Label>
                        <Form.Control
                          className=".inputs-info"
                          type="text"
                          id='nombre-proveedor'
                          name='nombre-proveedor'
                          placeholder="Escribe el nombre del proveedor"
                          onChange={props.handleChange}
                          readOnly="true"
                          value={props.values['nombre-proveedor']}
                          
                        >
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Fecha requerida de pago<span>*</span></Form.Label>
                        {
                        DebtsToPayData['Estatus']==="Pagado" &&
                        <Form.Control
                          readOnly={!DebtsToPayData["accion"]}
                          className=".inputs-info"
                          type="date"
                          min={moment().subtract(0, 'days').format("YYYY-MM-DD")}
                          id="fecha-requerida-de-pago"
                          name='fecha-requerida-de-pago'
                          value={props.values['fecha-requerida-de-pago']}
                          onChange={props.handleChange}
                          // isInvalid={!!props.errors['fecha-requerida-de-pago']}
                         
                        >
                        </Form.Control>
                        }
                        
                        
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['fecha-requerida-de-pago']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                    </Form.Row>


                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Requiere complemento</Form.Label>
                        <Form.Control
                          readOnly={!DebtsToPayData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='requiere-complemento-2'
                          name='requiere-complemento-2'
                          placeholder="Escribe el complemento"
                          onChange={props.handleChange}
                          value={props.values["requiere-complemento-2"]}
                        >
                          
                        </Form.Control>

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Complemento recibido</Form.Label>
                        <Form.Control
                          readOnly={!DebtsToPayData["accion"]}
                          className="provider-inputs"
                          type="text"
                          readOnly="true"
                          id='requiere-complemento'
                          name='requiere-complemento'
                          placeholder="Escribe el complemento"
                          onChange={props.handleChange}
                          value={props.values['requiere-complemento']}
                        >
                        </Form.Control>
                      </Form.Group>

                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Estatus<span>*</span></Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          // as="select"
                          type="text"
                          id='estatus'
                          name='estatus'
                          placeholder="Escribe el Estatus"
                          onChange={props.handleChange}
                          value={props.values.estatus}
                          required
                          // isInvalid={!!props.errors['estatus']}
                        >
                          {/* <option>Selecciona un estatus</option>
                          <option value="Pendiente">Pendiente</option>
                          <option value="Cancelado">Cancelado</option>
                          <option value="Pagado">Pagado</option>
                          <option value="Inactivo">Inactivo</option> */}
                          
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['estatus']}
                        </Form.Control.Feedback> */}

                      </Form.Group>
                      <Form.Group as={Col} md="4">
                      {/* <Form.Label className="label-provider">Usuario responsable</Form.Label>
                        <Form.Control
                          readOnly="true"
                          className="provider-inputs"
                          type="text"
                          id='Nombre Usuario Responsable'
                          name='Nombre Usuario Responsable'
                          placeholder="Escribe el Nombre del usuario responsable"
                          onChange={props.handleChange}
                          value={props.values['Nombre Usuario Responsable']}
                        >
                          
                        </Form.Control> */}
                      </Form.Group>

                    </Form.Row>


                    
                  </div>
                  {
                    DebtsToPayData["accion"] === true ?
                    <Button className='button primary send-button' type='submit'>
                      <span>Guardar</span>
                    </Button>
                    :
                    facturas(DebtsToPayData,data,setData)
                  }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 0
})

const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  cadena1= '$' + cadena1.split("").reverse().join('')
  return cadena1
          
       
}

const facturas = (DebtsToPayData,data,setData) =>{
  return (<Form>
  <Form.Row>
    <Form.Group as={Col} md="12">
      <Form.Label className="label-provider"><h1>Facturas</h1></Form.Label>
    </Form.Group>
  </Form.Row>
  {renderFacturas(data)}
  </Form>
  )
}



const get_facturas = async (DebtsToPayData,setData) =>{
  try{
    if(Object.entries(DebtsToPayData).length !== 0){
    const response = await Service.getInvoice({"item_id":DebtsToPayData["item_id"]})
    setData(response.data)
    renderFacturas(response.data)
    }
  }catch(error){

  }
}

const renderFacturas = (data) => data.map((invoice, index)=>{
  
    return(
      <Form>
        <Form.Row className="register-form-row justify-content-center">
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Id</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='ID'
              name='ID'
              value={invoice["ID"]}
              text={invoice["ID"]}
            >
              
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">UUID</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='UUID'
              name='UUID'
              value={invoice["UUID"]}
              text={invoice["UUID"]}
            >
              
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row className="register-form-row justify-content-center">
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Descripción o concepto</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Descripción o Concepto'
              name='Descripción o Concepto'
              value={invoice["Descripción o Concepto"]}
              text={invoice["Descripción o Concepto"]}
            >
              
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Estatus</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Estatus'
              name='Estatus'
              value={invoice["Estatus"]}
              text={invoice["Estatus"]}
            >
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row className="register-form-row justify-content-center">
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Folio factura</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Folio de Factura'
              name='Folio de Factura'
              value={invoice["Folio de Factura"]}
              text={invoice["Folio de Factura"]}
            >
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Metodo de pago</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Método de Pago'
              name='Método de Pago'
              value={invoice["Método de Pago"]}
              text={invoice["Método de Pago"]}
            >
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row className="register-form-row justify-content-center">
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Total</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Total'
              name='Total'
              value={montoDecimal(invoice["Total"])}
              text={invoice["Total"]}
            >
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label className="label-provider">Subtotal</Form.Label>
            <Form.Control
              readOnly="true"
              className="provider-inputs"
              type="text"
              id='Subtotal SIN IVA'
              name='Subtotal SIN IVA'
              value={montoDecimal(invoice["Subtotal SIN IVA"])}
              text={invoice["Subtotal SIN IVA"]}
            >
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    )
  })


const onSubmitForm = async (values, actions, history, item_id) => {
  try {
    const body = {
      "item_id":item_id,
      values
    }
    console.log(body)
    const response = await Service.updateDebtsToPay(body)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${DEBTS_TO_PAY_ROUTE}`)

  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'No se pudo procesar la solicitud',
      showConfirmButton: false,
      timer: 1500
    })
  }
}

const mapStateToProps = state => ({
  DebtsToPayData: state.editDebtsToPay
})

const mapDispatchToProps = {
  resetState,
}



export default connect(mapStateToProps, mapDispatchToProps)(EditdebtsToPay);
