import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux'
import { PROVIDER_REGISTER_ROUTE, EDIT_PROVIDER_ROUTE } from '../../../routes/types'
import {
  resetState,
} from './actions'
import './provider.scss';


const IssuedComplements = ({ history, complementsEmitedDataSAT }) => {
    
    const perfil = JSON.parse(localStorage.getItem('token')).perfil
    const [data, setData] = useState([])
    const [pageData, setPageData] = useState([])
    const [filtro, setFiltro] = useState([])

  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        let response;
        if(Object.entries(complementsEmitedDataSAT).length === 0){
          response = await Service.getComplementos1()
        }else{
          response = await Service.filterComplementos1({"Factura":complementsEmitedDataSAT["item_id"]})
        }
        
        if (isSuscribed) {
          setPageData(response.data['total-resultado'])
          setData(response.data.response)
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'No se pudo procesar la solicitud',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos"> Complementos emitidos SAT</h1>
        </Col>
      </Row>

      


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                <th>UUID</th>
                <th>Fecha de Emisión</th>
                <th>RFC Proveedor del Receptor</th>
                <th>Razón Social del Receptor</th>
                <th>Folio Factura</th>
                <th>Monto</th>
                <th>Estatus del SAT</th>
                <th>Archivos</th>
                
                
              </tr>
            </thead>
            <tbody>
              {renderList(data, history, perfil)}
            </tbody>
          </Table>
        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, history, perfil,filtro)}
    </Container>

  )
}


const searchBar = (setData,setPageData,setFiltro, body, setBody, results, perfil) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{ buscar: '', column: '' }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData, setPageData, setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option>Selecciona la columna a filtrar</option>
                <option value="RFC Proveedor">RFC Proveedor Receptor</option>
                <option value="UUID">UUID</option>
                <option value="Estatus">Estatus del SAT</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>

                <Form.Control
                  type="text"
                  name='buscar'
                  onChange={props.handleChange}
                  value={props.values.estado}
                  placeholder="Buscar"
                >
                </Form.Control>

                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData,setPageData,setFiltro) => {
  if(values.column==="Estatus"){
    values.buscar = values.buscar.toUpperCase()
  }
  try {
    const body = {
      [values.column]: values.buscar
    }
    setFiltro(body);
    console.log(body)
    const response = await Service.filterComplementos1(body)
    if (response.status === 200) {
      setData(response.data.response)
      setPageData(response.data["total-resultado"])
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }

    actions.resetForm()
  } catch (error) {
    Swal.fire({
      icon: "success",
      title: error.response.data.dsal.errno[0].mensaje,
      showConfirmButton: false,
      timer: 1500
    })
    console.error(error)
  }

}

const renderList = (issuedcomplements, history, perfil) => issuedcomplements.map((issuedcomplent, index) => {
  return <tr key={index}>
    <td>{issuedcomplent['UUID']}</td>
    <td>{issuedcomplent["Fecha de Emisión"][0]["start_date"]}</td>
    <td>{issuedcomplent['RFC Receptor']}</td>
    <td>{issuedcomplent['Razón Social Receptor']}</td>
    <td>{issuedcomplent['Folio']}</td>
    {montoDecimal(issuedcomplent['Monto'])}
    <td>{issuedcomplent['Estatus SAT']}</td>
    <td className="box_icons_tables">
    <a className="url-send" href={issuedcomplent["url-pdf"]}target="_blank">
        {/* <i
          className="fas fa-file-pdf i_icons">
        </i> */}
      </a>
      <a className="url-send" href={issuedcomplent["url-xml"]}target="_blank">
        <i
          className="fas fa-file i_icons">
        </i>
      </a>
    </td>
    {/* <td>{issuedcomplent['proveedor']}</td>
    <td>{issuedcomplent['id-proveedor-sap']}</td>
    <td>{issuedcomplent['direccion-fiscal']}</td>
    <td>{issuedcomplent['estatus']}</td> */}
    
   
    
  </tr>
})


const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  return <td>
    <Form>
      <Form.Row>
        <Form.Group as={Col} md="2">
          {'$'}
        </Form.Group>
        <Form.Group as={Col} md="10">
          {cadena1.split("").reverse().join('')}
        </Form.Group>
      </Form.Row>
    </Form>
  </td>
}

const assignData = (issuedcomplent, setUserData, history,accion) => {
  setUserData({"user":issuedcomplent,"accion":accion})
  localStorage.setItem('editarProveedor', JSON.stringify({"user":issuedcomplent,"accion":accion}))
  history.push(`/app${EDIT_PROVIDER_ROUTE}`)
}



const renderPagination = (data, setData, pageData, history, perfil, filtro) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,history,perfil,filtro)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body, data, setData, history, perfil, filtro) => {
  let response;
  console.log(filtro)
  try {
    if(filtro!==null && filtro.length!==0){
      if(filtro["RFC Proveedor"]!==undefined){body={"RFC Proveedor":filtro["RFC Proveedor"],"pagina":body.pagina}}
      if(filtro.UUID!==undefined){body={"UUID":filtro.UUID,"pagina":body.pagina}}
      if(filtro.Estatus!==undefined){body={"Estatus":filtro.Estatus,"pagina":body.pagina}}
      response = await Service.filterComplementos1(body)
    }
    else{
      response = await Service.getComplementos1Page(body);
    }
    setData(response.data.response);
    renderList(data, history, perfil)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const aprovar_proveedor = async (item_id, accion) => {
  try {
    const response = await Service.acceptProv({ "item_id":item_id,"acciones":accion})
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const mapDispatchToProps = {
  resetState,
}

const mapStateToProps = state => ({
  complementsEmitedDataSAT: state.filterComplementsEmitedSAT
})

export default connect(mapStateToProps, mapDispatchToProps)(IssuedComplements);
