import React, {useState, useEffect } from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { withRouter } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {
  PROVIDER_ROUTE,
  DEBTS_TO_PAY_ROUTE,
  GET_INVOICES_ROUTE,
  COMPLEMENTS_ROUTE,
  MY_DATA_ROUTE,
  USERS_ROUTE,
  SAT_INVOICES_RECEIVED_ROUTE,
  SAT_ISSUED_RECEIVED_ROUTE,
  SAT_COMPLEMENTS_RECEIVED_ROUTE,
  SAT_COMPLEMENTS_ISSUED_ROUTE,
  SAT_CONCILIATION_ROUTE ,
  SAT_NOTIFICATIONS_ROUTE,
  CONFIGURATION_ROUTE
} from '../../../../routes/types'
import './menuowner.scss'
import Service from '../../../../views/admin/myData/services'

const MenuOwner = ({ history, match}) => {
  const [owner,setOwner] = useState('')
  const [url,setUrl] = useState('')
  const [dropdown,setDropdown] = useState('')
  
  let path = window.location;
  path = path.href
  if(owner==="" || owner===null){
    if (path.indexOf("app/users")>-1 || 
          path.indexOf("app/editar-usuario")>-1 || 
            path.indexOf("app/crear-usuario")>-1) {
              setOwner(['colorSeleccion','','','','','','','','','','',''])

    } else if (path.indexOf("app/mis-datos")>-1){
        setOwner(['','colorSeleccion','','','','','','','','','',''])

    } else if (path.indexOf("app/proveedores")>-1 || 
                path.indexOf("app/editar-proveedor")>-1 || 
                  path.indexOf("app/crear-proveedor")>-1 ||
                    path.indexOf("app/registrar-proveedor-usuario")>-1){
                      setOwner(['','','colorSeleccion','','','','','','','','',''])

    } else if (path.indexOf("app/cuentas-por-pagar")>-1 || 
                path.indexOf("app/editar-cuentas-por-pagar")>-1) {
                  setOwner(['','','','colorSeleccion','','','','','','','',''])

    } else if (path.indexOf("app/complementos-pago")>-1 || 
                path.indexOf("app/editar-complemento")>-1) {
                  setOwner(['','','','','colorSeleccion','','','','','','',''])
           
    } else if (path.indexOf("app/obtener-facturas")>-1 || 
                path.indexOf("app/editar-facturas-recibidas")>-1) {
                  setOwner(['','','','','','colorSeleccion','','','','','',''])

    } else if (path.indexOf("app/facturas-recibidas-sat")>-1){
                  setOwner(['','','','','','','colorSeleccion','','','','',''])
                  setDropdown('colorSeleccion')

    } else if (path.indexOf("app/facturas-emitidas-sat")>-1){
                  setOwner(['','','','','','','','colorSeleccion','','','',''])
                  setDropdown('colorSeleccion')

    } else if (path.indexOf("app/complementos-recibidos-sat")>-1){
                  setOwner(['','','','','','','','','colorSeleccion','','',''])
                  setDropdown('colorSeleccion')

    } else if (path.indexOf("app/complementos-emitidos-sat")>-1){
                  setOwner(['','','','','','','','','','colorSeleccion','',''])
                  setDropdown('colorSeleccion')

    } else if (path.indexOf("app/conciliacion-sat")>-1){
                  setOwner(['','','','','','','','','','','colorSeleccion',''])
                  setDropdown('colorSeleccion')

    } else if (path.indexOf("app/notificaciones-sat")>-1){
                  setOwner(['','','','','','','','','','','','colorSeleccion'])
                  setDropdown('colorSeleccion')

    }
    setTimeout(function(){ refresh(setUrl) }, 2000);
  }
  
  

  return (
    <Container fluid={true} className="margin-navbar">
      <Navbar bg="primary" expand="lg" variant="dark" className="menu-sucursal">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <img src={url}  width="92" height="72"/>
            <Nav.Link className={owner[0]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,0,history,`${match.path}${USERS_ROUTE}`)}><i className="fas fa-users g_icons"></i> Usuarios</Nav.Link>
            <Nav.Link className={owner[1]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,1,history,`${match.path}${MY_DATA_ROUTE}`)}><i className="fas fa-user-circle g_icons"></i> datos</Nav.Link>
            <Nav.Link className={owner[2]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,2,history,`${match.path}${PROVIDER_ROUTE}`)}><i className="fas fa-truck g_icons"></i> proveedores</Nav.Link>
            <Nav.Link className={owner[3]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,3,history,`${match.path}${DEBTS_TO_PAY_ROUTE}`)}><i className="fas fa-file-invoice-dollar g_icons"></i> cuentas por pagar</Nav.Link>
            <Nav.Link className={owner[4]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,4,history,`${match.path}${COMPLEMENTS_ROUTE}`)}><i className="fas fa-file-alt g_icons"></i> complementos</Nav.Link>
            <Nav.Link className={owner[5]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,5,history,`${match.path}${GET_INVOICES_ROUTE}`)}><i className="fas fa-file-invoice g_icons"></i> facturas recibidas</Nav.Link>
            <NavDropdown title="Consulta al SAT" id="basic-nav-dropdown" className={dropdown}>
              <NavDropdown.Item className={owner[6]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,6,history,`${match.path}${SAT_INVOICES_RECEIVED_ROUTE}`)}> Facturas recibidas </NavDropdown.Item>
              <NavDropdown.Item className={owner[7]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,7,history,`${match.path}${SAT_ISSUED_RECEIVED_ROUTE}`)}> Facturas emitidas </NavDropdown.Item>
              <NavDropdown.Item className={owner[8]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,8,history,`${match.path}${SAT_COMPLEMENTS_RECEIVED_ROUTE}`)}>complementos<br/>recibidos</NavDropdown.Item>
              <NavDropdown.Item className={owner[9]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,9,history,`${match.path}${SAT_COMPLEMENTS_ISSUED_ROUTE}`)}>complementos<br/>emitidos</NavDropdown.Item>
              <NavDropdown.Item className={owner[10]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,10,history,`${match.path}${SAT_CONCILIATION_ROUTE }`)}> Conciliación </NavDropdown.Item>
              <NavDropdown.Item className={owner[11]} onClick={() => cambiarColor(setDropdown,setUrl,setOwner,11,history,`${match.path}${SAT_NOTIFICATIONS_ROUTE}`)}> Notificaciones </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

const cambiarColor = async (setDropdown,setUrl,setOwner,number,history,ruta) =>{
  setTimeout(function(){ setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"]) }, 2000);
  if(number===0){
    setOwner(['colorSeleccion','','','','','','','','','','',''])
    setDropdown('')
  } else if(number===1) {
    setOwner(['','colorSeleccion','','','','','','','','','',''])
    setDropdown('')
  } else if(number===2) {
    setOwner(['','','colorSeleccion','','','','','','','','',''])
    setDropdown('')
  } else if(number===3) {
    setOwner(['','','','colorSeleccion','','','','','','','',''])
    setDropdown('')
  } else if(number===4) {
    setOwner(['','','','','colorSeleccion','','','','','','',''])
    setDropdown('')
  } else if(number===5){
    setOwner(['','','','','','colorSeleccion','','','','','',''])
    setDropdown('')
  } else if (number===6){
    setOwner(['','','','','','','colorSeleccion','','','','',''])
    setDropdown('colorSeleccion')
  } else if (number===7){
    setOwner(['','','','','','','','colorSeleccion','','','',''])
    setDropdown('colorSeleccion')
  } else if (number===8){
    setOwner(['','','','','','','','','colorSeleccion','','',''])
    setDropdown('colorSeleccion')
  } else if (number===9) {
    setOwner(['','','','','','','','','','colorSeleccion','',''])
    setDropdown('colorSeleccion')
  } else if (number===10) {
    setOwner(['','','','','','','','','','','colorSeleccion',''])
    setDropdown('colorSeleccion')
  } else {
    setOwner(['','','','','','','','','','','','colorSeleccion'])
    setDropdown('colorSeleccion')
  }
  history.push(ruta)
  
}


const refresh = async (setUrl)=>{

  setUrl((JSON.parse(localStorage.getItem('logo-config')))["url-pub"])

}

export default withRouter(MenuOwner)