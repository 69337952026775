import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import { GET_INVOICES_ROUTE } from '../../../routes/types'
import * as Yup from "yup";
import {
  resetState,
} from './actions'
import { connect } from 'react-redux'


// const validationSchema = Yup.object().shape({
//   'descripcion-o-concepto': Yup.string().required("Campo requerido"),  

// });

const UploadInvoice = ({ history, match,setUploadInvoice }) => {
  const dataUser = JSON.parse(localStorage.getItem('token'))

  return (
    // <div className="provider-wrapper">

    <React.Fragment>
      <Row>
        
      </Row>
      <Container className="form-provider">
        <Row className="justify-content-center">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <i
            className="fas fa-arrow-left"
            id="get-back"
            onClick={() => history.push(`/app${GET_INVOICES_ROUTE}`)}
          />
        </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h1 className="titulos">Subir factura</h1>
          </Col>
        </Row>


        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                "descripcion-o-concepto": '',
                "facturaxml": '',
                "facturapdf": ''
              }}
              onSubmit={(values, actions) => onSubmitForm(values, history, actions,dataUser)}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <div>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Cargue su archivo xml<span> *</span></Form.Label>
                        <Form.File
                          className=".inputs-info"
                          type="file"
                          required
                          id='facturaxml'
                          name='facturaxml'
                          accept=".xml"
                          onChange={(event) => {
                            props.setFieldValue("facturaxml", event.currentTarget.files[0]);
                          }}
                        >
                        </Form.File>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Cargue su archivo pdf</Form.Label>
                        <Form.File
                          className=".inputs-info"
                          type="file"
                          id='facturapdf'
                          name='facturapdf'
                          accept=".pdf"
                          onChange={(event) => {
                            props.setFieldValue("facturapdf", event.currentTarget.files[0]);
                          }}
                        >
                        </Form.File>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="register-form-row justify-content-center">
                      <Form.Group as={Col} md="4">
                        <Form.Label className="label-provider">Descripción o concepto<span> *</span></Form.Label>
                        <Form.Control
                          className="provider-inputs"
                          type="text"
                          id='descripcion-o-concepto'
                          name='descripcion-o-concepto'
                          placeholder="Escribe el nombre del descripcion-o-concepto"
                          onChange={props.handleChange}
                          value={props.values['descripcion-o-concepto']}
                          required
                          // isInvalid={!!props.errors['descripcion-o-concepto']}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid">
                          {props.errors['descripcion-o-concepto']}
                        </Form.Control.Feedback> */}

                      </Form.Group>

                    </Form.Row>
                  </div>
                  <Button className='button primary send-button' type='submit'>
                    <span>Guardar</span>
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>

      </Container>
    </React.Fragment >
  )
}

const onSubmitForm = async (values, history, actions,dataUser) => {
  try {
    const body = new FormData()
    if(values['descripcion-o-concepto']!== null && values['descripcion-o-concepto']!==""){
    body.append('descripcion-o-concepto', values['descripcion-o-concepto'])
    }
    body.append('factura1', values.facturaxml)
    if(values.facturapdf!==null && values.facturapdf!==""){
      body.append('factura2', values.facturapdf)
    }
    body.append('user',dataUser['item_id'])
    body.append('configuracion',dataUser['configuracion'])
    const response = await Service.saveProvider(body);
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
    history.push(`/app${GET_INVOICES_ROUTE}`)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'No se pudo procesar la solicitud',
        showConfirmButton: false,
        timer: 1500
      })
    }
    actions.resetForm()
}

const mapDispatchToProps = {
  resetState,
}

const mapStateToProps = state => ({
  setUploadInvoice: state.uploadInvoices
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoice);
