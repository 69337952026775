import http from '../../../../http';


class Service {

  getSATinvoices = async () => {
    return await http.get({
      url: `/obtener-facturas-sat-emitidos`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getIssuedPage = async (body) => {
    return await http.post({
      url: `/obtener-facturas-sat-emitidos`,
      body
    })
  }


  filterIssued = async (body) => {
    return await http.post({
      url: `/obtener-facturas-sat-filtro-emitidos`,
      body
    })
  }
}

export default new Service()
