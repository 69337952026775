import http from '../../../../http';


class Service {

  editUser = async (body) => {
    return await http.put({
      url: `/editar-usuario`,
      body
    })
  }
}

export default new Service()
