import React, { useState } from 'react'
import { Formik } from 'formik'
// import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button';
import LoginService from './services'
import roles from '../../routes/roles'
import './login.scss'
import * as Yup from 'yup'
import RecuperarModal from '../../common/components/recuperarPassModal'

const Login = ({ history }) => {
  
  // const [showPassword, togglePassword] = useState('password')
  // const [iconClass, setIconClass] = useState('fas fa-eye')
  // const [cookies, setCookie, removeCookie] = useCookies(['user'])
  
  const [modalShow, setModalShow] = useState(false);
  
  return <Formik
    enableReinitialize={true}
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values, actions) => onSubmitLogin(values, actions, history)}
    render={state => renderForm({ ...state, setModalShow, modalShow})}
  />
}

const validationSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Campo requerido'),
  password: Yup
    .string()
    .required('Campo requerido'),
})

// const toggleClass = (showPassword, togglePassword, setIconClass) => {
//   if (showPassword === 'password') {
//     togglePassword('text')
//     setIconClass('fas fa-eye-slash')
//   }
//   if (showPassword === 'text') {
//     togglePassword('password')
//     setIconClass('fas fa-eye')
//   }
// }

const renderForm = ({
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  setModalShow,
  modalShow,
  showPassword,
  togglePassword,
  iconClass,
  setIconClass,
}) => <React.Fragment>
        <RecuperarModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
    <Container className="top-login">
      <Row className="justify-content-md-center align-items-center vh-100">
        
        <Col md={6} className="align-content-center">
          <Row> 
            <Col className="text-center mb-1">
             <h6><Col className="mb-1">
            <img src="/img/login/2021-03-01.png"/>
            </Col>CFDI Catcher</h6>
            </Col>
          </Row>
          <Row>
            <Col className="mt-1">
              <div className="login">
                <h1>Iniciar sesión</h1>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="loginUser">
                    <Form.Label></Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-envelope"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        className="input-login"
                        type="email"
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // maxLength='6'
                        placeholder="Email"
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.empleado}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="loginPassword">
                    <Form.Label></Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-lock-open"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        className="input-login"
                        type="password"
                        name='password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Contraseña"
                        isInvalid={!!errors.password}
                      />
                      {/* <InputGroup.Append onClick={() => toggleClass(showPassword, togglePassword, setIconClass)}>
                        <InputGroup.Text id="inputGroupPrepend"><i className={iconClass}></i></InputGroup.Text>
                      </InputGroup.Append> */}
                      <Form.Control.Feedback type="invalid">
                        {errors.password && touched.password && errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group controlId="loginRemember" className="mt-1">
                    <div className="mb-3">
                      <Form.Check
                        custom
                        checked={values.remember}
                        // value={values.remember}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="checkbox"
                        label="Recordar mi cuenta"
                        name="remember"
                        className="checklogin"
                      />
                    </div>
                  </Form.Group> */}
                  <Form.Label></Form.Label>
                  <Form.Group as={Row} >
                    <Col className="p-0">
                      <Button type="submit">Entrar</Button>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="loginRemember" className="mt-1">

                    <Row className="login-options">
                    <Col>

                    </Col>
                    <Col>
                    <span
                    onClick={() => setModalShow(true)}
                    className="right-login">¿Olvidó su contraseña?</span>
                    </Col>
                    </Row>
                    </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>

        </Col>
      </Row>
    </Container>
  </React.Fragment>

const setTokenInLocalStorage = async (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}

const onSubmitLogin = async (values, actions, history) => {
  try {
    const data = {
      email: values.email,
      password: values.password
    }
    const response = await LoginService.login(data)
    const token = response.data
    setTokenInLocalStorage(token)
    const ROUTE = `/app${roles[token.perfil].routes[0].url}`
    history.push(ROUTE)
  } catch (e) {
    let error = {}
    if (e.response) {
      error = e.response.data
      Swal.fire({
        icon: 'error',
        title: error.dsal.errno[0].mensaje,
        text: 'Usuario o contraseña incorrectos'
      })
    }
    else {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: (
          'No se puede iniciar sesión, ' +
          'Por favor ponerse en contacto con un administrador de la aplicación.'
        ),
      })
    }
    actions.setSubmitting(false)
    localStorage.clear()
  }
}


export default withRouter(Login)
