import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import roles from './roles'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import Login from "../views/login";
// import { createBrowserHistory } from 'history'


const authentication = () =>
  JSON.parse(localStorage.getItem('token')) ? (
    <Redirect to={`/app${roles[JSON.parse(localStorage.getItem('token')).perfil].routes[0].url}`} />
  ) : (
      <Login />
    );

export default () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path='/app' component={AuthenticatedRoutes} />
          <Route path="" render={authentication} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};
