import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Service from './services'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux'
import {
  setUserData
} from '../editUser/actions'
import { EDIT_USER_ROUTE, USER_REGISTER_ROUTE } from '../../../routes/types'

import './provider.scss';


const Users = ({ history, setUserData }) => {
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState([])

  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        const response = await Service.getUsers()
        if (isSuscribed) {
          setData(response.data.response)
          setPageData(response.data['total-resultado'])
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'No se pudo procesar la solicitud',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos">Usuarios</h1>
        </Col>
        <Col xs={6} sm={6} md={4} lg={6} xl={6}>
          <ButtonToolbar className="justify-content-center margin-withtable  mb-1">
            <Button variant="primary" type="submit" onClick={() => cancelarProveedor(history)}>
              agregar usuario
                  </Button>

          </ButtonToolbar>
        </Col>
      </Row>


      {/* <Row >
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ButtonToolbar className="justify-content-center margin-withtable  mb-1">
            <Button variant="primary" type="submit" onClick={() => history.push(`/app${USER_REGISTER_ROUTE}`)}>
              agregar usuario
                  </Button>

          </ButtonToolbar>
        </Col>
      </Row> */}


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>

          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                <th>Nombres</th>
                <th>Apellidos</th>
                <th>Email</th>
                <th>Tipo de usuario</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {renderList(data, history, setUserData)}
            </tbody>
          </Table>
        </Col>
      </Row>}
      {renderPagination(data, setData, pageData, history, setUserData)}
    </Container>
    

  )
}

const cancelarProveedor = (history) =>{
  localStorage.setItem('registrarProveedor', JSON.stringify({}))
  history.push(`/app${USER_REGISTER_ROUTE}`)
}

const renderList = (users, history, setUserData) => users.map((user, index) => {
  return <tr key={index}>
    <td>{user['Nombre(s)']}</td>
    <td>{user['Apellidos']}</td>
    <td>{user['Email'][0].value}</td>
    <td>{user['Tipo de Usuario']}</td>
    <td>{user['Estatus']}</td>
    <td className="box_icons_tables">
      <i
        onClick={() => deleteProvider(user['item_id'])}
        className="fas fa-trash i_icons">
      </i>
      <i
        onClick={() => assignData(user, setUserData, history, true)}
        className="fas fa-pen i_icons">
      </i>
      <i
        onClick={() => assignData(user, setUserData, history, false)}
        className="fas fa-eye i_icons">
      </i>
    </td>

  </tr>
})

const assignData = (user, setUserData, history, accion) => {
  localStorage.setItem('editarUsuario', JSON.stringify({"user":user,"accion":accion}))
  setUserData({"user":user,"accion":accion})
  history.push(`/app${EDIT_USER_ROUTE}`)
}

const deleteProvider = async (item_id) => {
  try {
    const response = await Service.deleteProv({ item_id })
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: 'Proceso correcto',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}


const renderPagination = (data,setData,pageData, history, setUserData) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,history,setUserData)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body,data,setData,history,setUserData) => {
  try {
    const response = await Service.getUsersPage(body);
    setData(response.data.response);
    renderList(data,history,setUserData)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}

const mapDispatchToProps = {
  setUserData,
}

export default connect(null, mapDispatchToProps)(Users);
