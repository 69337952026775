import http from '../../../../http';


class Service {

  getComplementos = async () => {
    return await http.get({
      url: `/obtener-complementos-sat-recibidos`,
    })
  }

  getLogo = async () => {
    return await http.get({
      url: `/configuracion-logo`,
    })
  }

  getComplementosPage = async (body) => {
    return await http.post({
      url: `/obtener-complementos-sat-recibidos`,
      body
    })
  }


  filterReceivedComplements = async (body) => {
    return await http.post({
      url: `/obtener-complementos-sat-filtro-recibidos`,
      body
    })
  }
}

export default new Service()
