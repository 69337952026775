import {
    COMPLEMENTS_EMITED_DATA_SAT,
    RESET_STATE
} from '../types'

export const ComplementsEmitedDataSat = value => (
    {
        type: COMPLEMENTS_EMITED_DATA_SAT,
        payload: value
    }
)

export const resetState = value => (
    {
        type: RESET_STATE
    }
)
