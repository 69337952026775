import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Service from './services'
import Swal from 'sweetalert2'
import { Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination';
import { connect } from 'react-redux'
import { DEBTS_TO_PAY_ROUTE, SAT_COMPLEMENTS_RECEIVED_ROUTE, SAT_COMPLEMENTS_ISSUED_ROUTE } from '../../../routes/types'
import './provider.scss';
import {DebtsToPayData} from '../../admin/debtsToPay/actions'
import {ComplementsDataSat} from '../ReceivedComplements/actions'
import {ComplementsEmitedDataSat} from '../IssuedComplemets/actions'

const Conciliation = ({ history, DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat}) => {
  // const perfil = JSON.parse(localStorage.getItem('token')).perfil
    const [data, setData] = useState([])
    const [pageData, setPageData] = useState([])
    const [filtro, setFiltro] = useState([])


  useEffect(() => {
    let isSuscribed = true
    const getData = async () => {
      try {
        const respuesta = await Service.getLogo()
        localStorage.setItem('logo-config', JSON.stringify({"url-pub":respuesta.data["url"]}))
        const response = await Service.conciliacionGet()
        

        if (isSuscribed) {
          setData(response.data.response)
          setPageData(response.data["total-resultado"])
          
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'No se pudo procesar la solicitud',
          confirmButtonColor: '#e35205',
          confirmButtonText: '<i class="fa fa-check"></i>',
          customClass: {
            popup: 'elimcontent',
            closeButton: 'closelimn',
            icon: 'iconelim',
            confirmButton: 'checkswal',
          }
        })
        console.error(error);
      }
    }
    getData()
    return () => isSuscribed = false
  }, [history])
  return (

    <Container>
      <Row className="justify-content-center">
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <h1 className="titulos"> Conciliación SAT</h1>
        </Col>
      </Row>

      


      {<Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {searchBar(setData,setPageData,setFiltro)}
          <Table responsive bordered hover id="inv-apld">
            <thead>
              <tr className="bg-primary">
                <th>UUID</th>
                <th>Fecha de Emisión</th>
                <th>RFC Proveedor</th>
                <th>Razón Social</th>
                <th>Folio Factura</th>
                <th>Subtotal sin IVA</th>
                <th>Método de Pago</th>
                <th>Estatus del SAT</th>
                <th>Complemento</th>
                <th>Existente</th>
                <th>Estatus de la Existente</th>
                <th>Archivos</th>
                
              </tr>
            </thead>
            <tbody>
              {renderList(data,DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history)}
            </tbody>
          </Table>
        </Col>
      </Row>}
      {renderPagination(data, setData, pageData,filtro,DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history)}
    </Container>

  )
}


const searchBar = (setData,setPageData,setFiltro) => {
  return <Formik
    enableReinitialize={true}
    initialValues={{ buscar: '', column: '', buscar1: '', column1: '' }}
    onSubmit={(values, actions) => onSubmitSearch(values, actions, setData, setPageData, setFiltro)}
  >
    {props => (
      <Form onSubmit={props.handleSubmit}>
        <div>
          <Form.Row>
            <Form.Group as={Col} md="2.3">
              <Form.Control
                as="select"
                id='column'
                name='column'
                onChange={props.handleChange}
                value={props.values.column}
              >
                <option value="">Seleccione un filtro</option>
                <option value="Metodo">Método de Pago</option>
                <option value="Complemento">Complemento</option>
                <option value="Estatus">Estatus de la Existente</option>
                <option value="Existencia">Existencia</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <InputGroup>
                <Form.Control
                  type="text"
                  name='buscar'
                  id="buscar"
                  onChange={props.handleChange}
                  value={props.values.buscar}
                  placeholder="Buscar"
                >
                </Form.Control>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="2.3">
              <Form.Control
                as="select"
                id='column1'
                name='column1'
                onChange={props.handleChange}
                value={props.values.column1}
              >
                <option value="">Seleccione un filtro</option>
                <option value="Metodo">Método de Pago</option>
                <option value="Complemento">Complemento</option>
                <option value="Estatus">Estatus de la Existente</option>
                <option value="Existencia">Existencia</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="3.9">
              <InputGroup>
                <Form.Control
                  type="text"
                  name='buscar1'
                  id="buscar1"
                  onChange={props.handleChange}
                  value={props.values.buscar1}
                  placeholder="Buscar"
                >
                </Form.Control>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend >
                  <Button type="submit" variant="light" className="btnsearch" >
                    <InputGroup.Text id="inputGroupAppend" className="search"><i className="fas fa-search"></i></InputGroup.Text>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
    )}
  </Formik>
}

const onSubmitSearch = async (values, actions, setData,setPageData,setFiltro) => {
  values.buscar = values.buscar.toUpperCase()
  values.buscar1 = values.buscar1.toUpperCase()
  if(values.column===values.column1){
    values.column1="";
  }
  console.log(values)
  if(values.column!=="" && values.buscar!==""){
    try {
      setFiltro(values);
      const response = await Service.filterConciliacion(values)
      if (response.status === 200) {
        setData(response.data.response)
        setPageData(response.data["total-resultado"])
        Swal.fire({
          icon: 'success',
          text: 'Proceso correcto',
          showConfirmButton: false,
          timer: 1500
        })
      }

      actions.resetForm()
    } catch (error) {
      Swal.fire({
        icon: "success",
        title: error.response.data.dsal.errno[0].mensaje,
        showConfirmButton: false,
        timer: 2000
      })
      console.error(error)
    }
  }else if(values.column==="" && values.column===""){
    Swal.fire({
      icon: "warning",
      title: "Debe de seleccionar al menos un campo a filtrar",
      showConfirmButton: false,
      timer: 1500
    })
  }else{
    Swal.fire({
      icon: "warning",
      title: "No puede filtrar un campo vacio",
      showConfirmButton: false,
      timer: 2000
    })

  }
}

const renderList = (conciliations,DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history) => conciliations.map((conciliation, index) => {
  return <tr key={index}>
    <td>{conciliation['uuid']}</td>
    <td>{conciliation['fecha-emision-2']}</td>
    <td>{conciliation['rfc-proveedor']}</td>
    <td>{conciliation['razon-social']}</td>
    <td>{conciliation['folio-factura']}</td>
    {montoDecimal(conciliation['subtotal-sin-iva'])}
    <td>{conciliation["metodo-de-pago"]}</td>
    <td>{conciliation['estatus-sat-2']}</td>
    <td onClick={()=>filterComplements(conciliation,ComplementsDataSat,ComplementsEmitedDataSat,history)}>{conciliation["ref-complementos"]}</td>
    <td onClick={() => filterDebsToPayData(conciliation,DebtsToPayData,history)}>{conciliation['estatus']}</td>
    <td>{conciliation['estatus-de-la-existente']}</td>
    <td>
      <a className="url-send" href={conciliation['url-xml']}target="_blak">
          <i
            className="fas fa-file i_icons">
          </i>
      </a>
    </td>
    
  </tr>
})

const filterComplements = async (conciliation,ComplementsDataSat,ComplementsEmitedDataSat,history) => {
  if(conciliation["ref-complementos"]==="Si"){
    const response = await Service.filterComplements({item_id:conciliation["item_id"]});
    if(response.data.Tipo==="Recibido"){
      ComplementsDataSat({"item_id":conciliation["item_id"]})
      history.push(`/app${SAT_COMPLEMENTS_RECEIVED_ROUTE}`)
    }else if(response.data.Tipo==="Emitido"){
      ComplementsEmitedDataSat({"item_id":conciliation["item_id"]})
      history.push(`/app${SAT_COMPLEMENTS_ISSUED_ROUTE}`)
    }
  }

}

const filterDebsToPayData = (conciliation,DebtsToPayData,history) => {
  if(conciliation["cuenta-por-pagar"]!==undefined){
    const body = {
      "item_id" : conciliation["cuenta-por-pagar"]["item_id"]
    }
    DebtsToPayData(body)
    console.log(body)
    history.push(`/app${DEBTS_TO_PAY_ROUTE}`)
}
}


const montoDecimal = (cantidad) => {
  var num = parseFloat(cantidad).toFixed(2);
  var cadena = num.toString();
  var arregloDeSubCadenas = cadena.split("").reverse();
  var a;
  var cont = 0
  var cadena1 = "";

  for (a = 0; a < arregloDeSubCadenas.length; a++) {
    if (a > 2) {
      if (cont == 3) {
        cadena1 = cadena1 + ",";
        cont = 0;
      }
      cont = cont + 1;
    }
    cadena1 = cadena1 + arregloDeSubCadenas[a];
  }
  return <td>
    <Form>
      <Form.Row>
        <Form.Group as={Col} md="2">
          {'$'}
        </Form.Group>
        <Form.Group as={Col} md="10">
          {cadena1.split("").reverse().join('')}
        </Form.Group>
      </Form.Row>
    </Form>
  </td>
}


const renderPagination = (data, setData, pageData, filtro, DebtsToPayData ,ComplementsDataSat, ComplementsEmitedDataSat, history) => {
  let i = 0;
  let cont = 0
  let items = [];
  while(i<pageData){
    i = i + 20;
    cont = cont + 1;
    items.push(cont)
  }
  return(
    <div>
      <Pagination >{items.map((item,index)=>{
        return <span key={index}>
            <Pagination.Item onClick={()=>paginacion({"pagina":item},data,setData,filtro,DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history)}>{item}</Pagination.Item>
          </span>
      })}</Pagination>
    </div>
  );
}

const paginacion = async (body, data, setData, filtro, DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history) => {
  let response;
  try {
    if(filtro["column"]!==undefined && filtro["buscar"]!==undefined){
      if(filtro["column"]!=="Estatus"){
        body={
          "column":filtro["column"],
          "buscar":filtro["buscar"],
          "pagina":body.pagina
        }
      }
      if(filtro["column"]!=="Existencia"){
        body={
          "column":filtro["column"],
          "buscar":filtro["buscar"],
          "pagina":body.pagina
        }
      }
      if(filtro["column"]!=="Metodo"){
        body={
          "column":filtro["column"],
          "buscar":filtro["buscar"],
          "pagina":body.pagina
        }
      }
      if(filtro["column"]!=="Complemento"){
        body={
          "column":filtro["column"],
          "buscar":filtro["buscar"],
          "pagina":body.pagina
        }
      }
        response = await Service.filterConciliacion(body);
    }else{
      response = await Service.conciliacionPost(body)
    }
    setData(response.data.response);
    renderList(data,DebtsToPayData,ComplementsDataSat,ComplementsEmitedDataSat,history)
  } catch (error) {
    Swal.fire({
      icon: 'warning',
      title: 'No se pudo procesar la solicitud',
      confirmButtonColor: '#e35205',
      confirmButtonText: '<i class="fa fa-check"></i>',
      customClass: {
        popup: 'elimcontent',
        closeButton: 'closelimn',
        icon: 'iconelim',
        confirmButton: 'checkswal',
      }
    })
    console.error(error);
  }
}


const mapDispatchToProps = {
  DebtsToPayData,
  ComplementsDataSat,
  ComplementsEmitedDataSat,
}

export default connect(null, mapDispatchToProps)(Conciliation);
