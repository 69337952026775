import {
    SET_PAGINATION_RESULTS,
} from '../types'

const defaultState = {
    results: 1,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_PAGINATION_RESULTS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
